// global constant - put your variables that are used in any parts of the component
export const drawerWidth = {
  open: 230,
  close: 56,
}

export const API_ENDPOINT = {
  /*Login*/
  LOGIN: 'login',
  LOGOUT: 'logout',
  /*Role*/
  GET_ROLE: 'getRole',
  ALL_ROLE: 'getAllRole',
  SHOW_ROLE: 'showRole',
  POST_ROLE: 'postRole',
  PUT_ROLE: 'updateRole',
  DELETE_ROLE: 'deleteRole',
  /*Employee*/
  GET_EMPLOYEE: 'getEmployee',
  ALL_EMPLOYEE: 'getAllEmployee',
  SHOW_EMPLOYEE: 'showEmployee',
  SHOW_EMPLOYEE2: 'showEmployee2',
  POST_EMPLOYEE: 'postEmployee',
  POST_EMPLOYEE2: 'postEmployee2',
  PUT_EMPLOYEE: 'updateEmployee',
  PUT_EMPLOYEE2: 'updateEmployee2',
  BULK_UPLOAD_EMPLOYEE: 'bulkUploadEmployee',
  GET_EMPLOYEE_BY_SEARCH: 'getEmployeeBySearched',
  ALL_SUPERVISOR: 'getAllSupervisor',
  DOWNLOAD_EMPLOYEE_SAMPLE_FORMAT: 'downloadEmployeeSampleFormat',
  EMPLOYEE_BASED_ON_BRANCH: 'getEmployeeBasedOnBranch',
  EMPLOYEE_SCHEDULE_CALENDAR_TYPE: 'getEmployeeScheduleCalendarType',
  GET_EMPLOYEE_SAME_BRANCH_DEPARTMENT: 'getEmployeeSameBranchDepartment',
  ALL_APPROVERS: 'getAllApprovers',
  UPLOAD_PROFILE_PICTURE: 'uploadProfilePicture',
  UPLOAD_DOCUMENTS_ON_EMPLOYEE: 'uploadDocumentsForEmployee',
  DOWNLOAD_DOCUMENT_ON_EMPLOYEE: 'downloadDocumentOnEmployee',
  POST_EMPLOYEE_FROM_CANDIDATE: 'postEmployeeFromCandidate',
  UPDATE_PASSWORD: 'updatePassword',
  GET_EMPLOYEES_BY_ROLE: 'getEmployeeByRole',
  /*Candidate*/
  GET_CANDIDATES: 'getCandidates',
  POST_CANDIDATE: 'postCandidate',
  PUT_CANDIDATE: 'putCandidate',
  SHOW_CANDIDATE: 'showCandidate',
  DELETE_CANDIDATE: 'deleteCandidate',
  DOWNLOAD_CANDIDATE_SAMPLE_FORMAT: 'downloadCandidateSampleFormat',
  BULK_UPLOAD_CANDIDATE: 'bulkUploadCandidate',
  GET_ALL_CANDIDATES: 'getAllCandidates',
  /*Department*/
  GET_DEPARTMENT: 'getDepartment',
  ALL_DEPARTMENT: 'getAllDepartment',
  SHOW_DEPARTMENT: 'showDepartment',
  POST_DEPARTMENT: 'postDepartment',
  PUT_DEPARTMENT: 'updateDepartment',
  DELETE_DEPARTMENT: 'deleteDepartment',
  /*Designation*/
  GET_DESIGNATION: 'getDesignation',
  ALL_DESIGNATION: 'getAllDesignation',
  SHOW_DESIGNATION: 'showDesignation',
  POST_DESIGNATION: 'postDesignation',
  PUT_DESIGNATION: 'updateDesignation',
  DELETE_DESIGNATION: 'deleteDesignation',
  /*Job Level*/
  GET_JOB_LEVEL: 'getJobLevel',
  ALL_JOB_LEVEL: 'getAllJobLevel',
  SHOW_JOB_LEVEL: 'showJobLevel',
  POST_JOB_LEVEL: 'postJobLevel',
  PUT_JOB_LEVEL: 'updateJobLevel',
  DELETE_JOB_LEVEL: 'deleteJobLevel',
  /*Branch*/
  GET_BRANCH: 'getBranch',
  ALL_BRANCH: 'getAllBranch',
  SHOW_BRANCH: 'showBranch',
  POST_BRANCH: 'postBranch',
  PUT_BRANCH: 'updateBranch',
  DELETE_BRANCH: 'deleteBranch',
  /*Team*/
  GET_TEAM: 'getTeam',
  ALL_TEAM: 'getAllTeam',
  SHOW_TEAM: 'showTeam',
  POST_TEAM: 'postTeam',
  PUT_TEAM: 'updateTeam',
  DELETE_TEAM: 'deleteTeam',
  /*Attendance*/
  GET_ATTENDANCE_OVERVIEW: 'getAttendanceOverview',
  GET_ATTENDANCE_OVERVIEW_FOR_SOS: 'getAttendanceOverviewForSOS',
  GET_ATTENDANCE_OVERVIEW_FOR_JVD: 'getAttendanceOverviewForJDV',
  GET_ATTENDANCE_OVERVIEW_FOR_MFBC: 'getAttendanceOverviewForMFBC',
  GET_ATTENDANCE_OVERVIEW_FOR_RDS: 'getAttendanceOverviewForRDS',
  EXPORT_ATTENDANCE_OVERVIEW_FOR_MFBC: 'exportAttendanceOverviewForMFBC',
  GET_ATTENDANCE_BY_USER: 'getAttendanceByUser',
  GET_ATTENDANCE_BY_USER_FOR_JVD: 'getAttendanceByUserForJVD',
  GET_ATTENDANCE_BY_USER_FOR_MFBC: 'getAttendanceByUserForMFBC',
  GET_ATTENDANCE_BY_USER_FOR_MFBC_FOR_EXPORT: 'getAttendanceByUserForMFBCForExport',
  GET_ATTENDANCE_BY_USER_FOR_MFBC_FOR_PAYROLL: 'getAttendanceByUserForMFBCForPayroll',
  GET_ATTENDANCE_BY_USER_FOR_RDS: 'getAttendanceByUserForRDS',
  GET_ATTENDANCE_BY_USER_FOR_RDS_FOR_EXPORT: 'getAttendanceByUserForRDSForExport',
  GET_ATTENDANCE_BY_USER_FOR_JVD_FOR_PAYROLL: 'getAttendanceByUserForJVDForPayroll',
  GET_ATTENDANCE_BY_USER_FOR_JVD_FOR_EXPORT: 'getAttendanceByUserForJVDForExport',
  EXPORT_ATTENDANCE_BY_USER_FOR_MFBC: 'exportAttendanceByUserForMFBC',
  GET_ATTENDANCE_IMAGE_LOCATION_FOR_JVD: 'getAttendanceImageLocationForJVD',
  GET_ATTENDANCE_IMAGE_LOCATION_FOR_JVD_FOR_PAYROLL: 'getAttendanceImageLocationForJVDForPayroll',
  GET_ATTENDANCE_IMAGE_LOCATION_FOR_ROPISA: 'getAttendanceImageLocationForRopisa',
  GET_ATTENDANCE_IMAGE_LOCATION_TODAY_FOR_JVD: 'getAttendanceImageLocationTodayForJVD',
  GET_ATTENDANCE_IMAGE_LOCATION_TODAY_FOR_ROPISA: 'getAttendanceImageLocationTodayForRopisa',
  GET_ATTENDANCE_TODAY: 'getAttendanceByToday',
  GET_ATTENDANCE_TODAY_FOR_JVD: 'getAttendanceByTodayForJVD',
  GET_ATTENDANCE_TODAY_FOR_MFBC: 'getAttendanceByTodayForMFBC',
  GET_ATTENDANCE_TODAY_FOR_RDS: 'getAttendanceByTodayForRDS',
  GET_ATTENDANCE_BY_DAY_FOR_JVD: 'getAttendanceByDayForJVD',
  GET_ATTENDANCE_BY_DAY_FOR_MFBC: 'getAttendanceByDayForMFBC',
  GET_ATTENDANCE_BY_DAY_FOR_RDS: 'getAttendanceByDayForRDS',
  GET_ATTENDANCE_TIME: 'getAttendanceTime',
  GET_ATTENDANCE_TIME_FOR_JVD: 'getAttendanceTimeForJVD',
  GET_ATTENDANCE_TIME_FOR_MFBC: 'getAttendanceTimeForMFBC',
  GET_ATTENDANCE_TIME_FOR_RDS: 'getAttendanceTimeForRDS',
  POST_ATTENDANCE_TIME: 'postAttendanceTime',
  POST_ATTENDANCE_TIME_FOR_ROPISA: 'postAttendanceTimeForRopisa',
  POST_ATTENDANCE_TIME_FOR_JVD: 'postAttendanceTimeForJVD',
  POST_ATTENDANCE_TIME_FOR_MFBC: 'postAttendanceTimeForMFBC',
  POST_ATTENDANCE_TIME_FOR_RDS: 'postAttendanceTimeForRDS',
  GET_ATTENDANCE_AUTH: 'getAttendanceAuth',
  GET_ATTENDANCE_TEAM: 'getAttendanceTeam',
  GET_ATTENDANCE_TEAM_FOR_JVD: 'getAttendanceTeamForJVD',
  GET_ATTENDANCE_PER_USER_AND_PER_DATE: 'getAttendanceByUserAndDate',
  GET_ATTENDANCE_PER_USER_AND_PER_DATE_WITHOUT_WORKSITE: 'getAttendanceByUserAndDateWithoutWorksite',
  PUT_ATTENDANCE: 'updateAttendance',
  PUT_ATTENDANCE_WITHOUT_WORKSITE: 'updateAttendanceWithoutWorksite',
  PUT_ATTENDANCE_FOR_JVD: 'updateAttendanceForJVD',
  PUT_ATTENDANCE_FOR_STANDARD: 'updateAttendanceForStandard',
  UPLOAD_ATTENDANCE: 'uploadAttendance',
  EXPORT_ATTENDANCE_BY_USER_ROPISA: 'downloadAttendanceByUserForRopisa',
  GET_ACTIVITY_TIME: 'getActivityTime',
  GET_ACTIVITY_PER_TIME: 'getDataPerTimeForPieChart',
  /*Check In*/
  POST_CHECK_IN : 'postCheckIn',
  /*Device*/
  GET_DEVICES_ALLLOWED: 'getAllDeviceAllowed',
  GET_DEVICES_NOT_ALLLOWED: 'getAllDeviceNotAllowed',
  GET_DEVICES_ALLLOWED_FOR_STANDARD: 'getAllDeviceAllowedForStandard',
  GET_DEVICES_NOT_ALLLOWED_FOR_STANDARD: 'getAllDeviceNotAllowedForStandard',
  POST_DEVICE: 'postDevice',
  APPROVE_DISAPPROVE_DEVICE: 'approveDisapproveDevice',
  DELETE_DEVICE: 'deleteDevice',
  GET_WHITE_LISTED: 'getWhiteListedDevice',
  POST_WHITE_LISTED: 'postWhiteListed',
  DELETE_WHITE_LISTED: 'deleteWhiteListed',
  /*Notice*/
  GET_NOTICE: 'getNotice',
  GET_NOTICE_PUBLISH: 'getNoticePublish',
  SHOW_NOTICE: 'showNotice',
  POST_NOTICE: 'postNotice',
  PUT_NOTICE: 'updateNotice',
  DELETE_NOTICE: 'deleteNotice',
  /*Holiday*/
  GET_HOLIDAY: 'getHoliday',
  GET_HOLIDAY_FOR_STANDARD: 'getHolidayForStandard',
  SHOW_HOLIDAY: 'showHoliday',
  POST_HOLIDAY: 'postHoliday',
  POST_HOLIDAY_FOR_STANDARD: 'postHolidayForStandard',
  PUT_HOLIDAY: 'updateHoliday',
  DELETE_HOLIDAY: 'deleteHoliday',
  /*Holiday Type*/
  GET_HOLIDAY_TYPE: 'getHolidayType',
  ALL_HOLIDAY_TYPE: 'getAllHolidayType',
  SHOW_HOLIDAY_TYPE: 'showHolidayType',
  POST_HOLIDAY_TYPE: 'postHolidayType',
  PUT_HOLIDAY_TYPE: 'updateHolidayType',
  DELETE_HOLIDAY_TYPE: 'deleteHolidayType',
  /*Special Event*/
  GET_SPECIAL_EVENT: 'getSpecialEvent',
  /*Statistics*/
  GET_STATISTICS: 'getStatistics',
  /*Modules*/
  GET_MODULES: 'getModules',
  GET_MENUS: 'getMenus',
  GET_SUBMENUS : 'getSubMenus',
  POST_MENUS: 'postMenus',
  GET_MODULES_BY_ROLE: 'getModulesByRole',
  /*Leaves Request*/
  GET_LEAVE_REQUEST: 'getLeaveRequest',
  GET_LEAVE_REQUEST_FOR_MFBC: 'getLeaveRequestForMFBC',
  PENDING_LEAVE_REQUEST: 'pendingLeaveRequest',
  SHOW_LEAVE_REQUEST: 'showLeaveRequest',
  SHOW_LEAVE_REQUEST_FOR_MFBC: 'showLeaveRequestForMFBC',
  ACCEPT_LEAVE_REQUEST: 'acceptLeaveRequest',
  ACCEPT_LEAVE_REQUEST_FOR_MFBC: 'acceptLeaveRequestForMFBC',
  REJECT_LEAVE_REQUEST: 'rejectLeaveRequest',
  REJECT_LEAVE_REQUEST_FOR_MFBC: 'rejectLeaveRequestForMFBC',
  GET_ALL_REQUEST: 'getAllRequest',
  GET_ALL_REQUEST_FOR_MFBC: 'getAllRequestForMFBC',
  EXPORT_LEAVE_APPROVAL: 'exportLeaveApproval',
  GET_ALL_LEAVE_APPLICATIONS: 'getAllLeaveApplication',
  GET_LEAVE_REPORTS_FOR_CHARTS: 'getLeaveReportsForCharts',
  GET_ALL_PENDING_LEAVE_REQUESTS: 'getAllPendingLeaveRequest',
  /*Leave Balance*/
  SHOW_LEAVE_BALANCE: 'showLeaveBalance',
  SHOW_LEAVE_CREDITS: 'showLeaveCredit',
  /*Leave Configuration*/
  GET_LEAVE_CONFIGURATION: 'getLeaveConfiguration',
  ALL_LEAVE_CONFIGURATION: 'getAllLeaveConfiguration',
  SHOW_LEAVE_CONFIGURATION: 'showLeaveConfiguration',
  POST_LEAVE_CONFIGURATION: 'postLeaveConfiguration',
  PUT_LEAVE_CONFIGURATION: 'updateLeaveConfiguration',
  DELETE_LEAVE_CONFIGURATION: 'deleteLeaveConfiguration',
  ASSIGN_LEAVE_CREDIT: 'assignLeaveCredit',
  GET_LEAVE_CREDIT_HISTORY: 'getLeaveCreditsHistory',
  GET_EMPLOYEES_ASSIGNED: 'getEmployeesAssigned',
  GET_EMPLOYEE_FILTER_OPTION: 'getEmployeeFilterOptions',
  GET_EMPLOYEE_FOR_LEAVE_CREDITS: 'getEmployeeForLeaveCredits',
  /*Leaves Application*/
  GET_LEAVE_APPLICATION: 'getLeaveApplication',
  SHOW_LEAVE_APPLICATION: 'showLeaveApplication',
  POST_LEAVE_APPLICATION: 'postLeaveApplication',
  POST_LEAVE_APPLICATION_FOR_MFBC: 'postLeaveApplicationForMFBC',
  PUT_LEAVE_APPLICATION: 'updateLeaveApplication',
  DELETE_LEAVE_APPLICATION: 'deleteLeaveApplication',
  REQUEST_LEAVE_APPLICATION: 'getLeaveApplicationRequest',
  GET_EMPLOYEES_LEAVE_ON_DATE: 'getEmployeesLeaveUnderOnDate',
  /*Shift*/
  GET_SHIFT: 'getShift',
  ALL_SHIFT: 'getAllShift',
  SHOW_SHIFT: 'showShift',
  POST_SHIFT: 'postShift',
  PUT_SHIFT: 'updateShift',
  DELETE_SHIFT: 'deleteShift',
  GET_EMPLOYEE_SHIFT: 'getEmployeeShift',
  GET_EMPLOYEE_SHIFT_FOR_MFBC: 'getEmployeeShiftForMFBC',
  GET_SHIFT_NAME: 'getShiftName',
  GET_ALL_SHIFT_NAME: 'getAllShiftName',
  POST_SHIFT_NAME: 'postShiftName',
  PUT_SHIFT_NAME: 'putShiftName',
  DELETE_SHIFT_NAME: 'deleteShiftName',
  SHOW_SHIFT_NAME: 'showShiftName',
  /*Pay Grade*/
  GET_PAY_GRADE: 'getPayGrade',
  ALL_PAY_GRADE: 'getAllPayGrade',
  SHOW_PAY_GRADE: 'showPayGrade',
  POST_PAY_GRADE: 'postPayGrade',
  PUT_PAY_GRADE: 'updatePayGrade',
  DELETE_PAY_GRADE: 'deletePayGrade',
  /*Allowance*/
  GET_ALLOWANCE: 'getAllowance',
  ALL_ALLOWANCE: 'getAllAllowance',
  ALL_ALLOWANCE_FOR_EMPLOYEE: 'getAllAllowanceForEmployee',
  SHOW_ALLOWANCE: 'showAllowance',
  POST_ALLOWANCE: 'postAllowance',
  PUT_ALLOWANCE: 'updateAllowance',
  DELETE_ALLOWANCE: 'deleteAllowance',
  GET_NON_RECURRING_ALLOWANCE: 'getAllNonRecurringAllowances',
  POST_NON_RECURRING_ALLOWANCE: 'postNonRecurringAllowance',
  /*DE MINIMIS*/
  GET_DEMINIMIS: 'getDeMinimis',
  GET_ALL_DEMINIMIS: 'getAllDeMinimis',
  /*Deduction*/
  GET_DEDUCTION: 'getDeduction',
  ALL_DEDUCTION: 'getAllDeduction',
  ALL_DEDUCTION_FOR_EMPLOYEE: 'getAllDeductionForEmployee',
  SHOW_DEDUCTION: 'showDeduction',
  POST_DEDUCTION: 'postDeduction',
  PUT_DEDUCTION: 'updateDeduction',
  DELETE_DEDUCTION: 'deleteDeduction',
  GET_NON_RECURRING_DEDUCTION: 'getNonRecurringDeductions',
  POST_NON_RECURRING_DEDUCTION: 'postNonRecurringDeduction',
  /*Payroll Computation*/
  ALL_PAYROLL_COMPUTATION: 'getAllPayrollComputation',
  UPDATE_PAYROLL_COMPUTATION: 'updatePayrollComputation',
  CURRENT_PAYROLL_COMPUTATION: 'getCurrentPayrollComputation',
  ALL_PAYROLL_RANGE: 'getAllPayrollRange',
  CURRENT_PAYROLL_RANGE: 'getCurrentPayrollRange',
  UPDATE_PAYROLL_RANGE: 'updatePayrollRange',
  POST_PAYROLL_RANGE: 'addPayrollRange',
  ASSIGN_PAYROLL_RANGE: 'assignEmployeePayrollRange',
  ALL_EMPLOYEES_PAYROLL_RANGE: 'getEmployeesOnPayrollRange',
  TRANSFER_EMPLOYEE_PAYROLL_RANGE: 'transferEmployee',
  ALL_EMPLOYEE_UNASSIGNED: 'getAllEmployeeUnAssigned',
  ALL_USED_PAYROLL_RANGE: 'getAllUsedPayrollRange',
  ALL_EMPLOYEES_PAYROLL_RANGE_NO_PAGINATION: 'getEmployeesOnPayrollRangeNoPagination',
  SHOW_PAYROLL_RANGE: 'showPayrollRange',
  EDIT_PAYROLL_RANGE: 'editPayrollRange',
  PAYROLL_RANGE_LIST: 'getPayrollRangeList',
  GET_ALL_PAYROLL_RANGE: 'getAllPayrollRange',
  /*Late Computation */
  GET_LATE_COMPUTATION: 'getAllLateComputation',
  ALL_EMPLOYEES_LATE_COMPUTATION: 'getEmployeesOnLateComputation',
  ALL_EMPLOYEE_UNASSIGNED_LATE_COMPUTATION: 'getAllEmployeeUnAssignedLateComputation',
  ASSIGN_LATE_COMPUTATION: 'assignEmployeeLateComputation',
  UPDATE_LATE_COMPUTATION: 'updateLateComputation',
  ALL_EMPLOYEES_LATE_COMPUTATION_NO_PAGINATION: 'getEmployeesOnLateComputationNoPagination',
  ALL_USED_LATE_COMPUTATION: 'getAllUsedLateComputation',
  TRANSFER_EMPLOYEE_LATE_COMPUTATION: 'transferEmployeeLateComputation',
  /*Overtime */
  GET_OVERTIME: 'getOvertime',
  SHOW_OVERTIME: 'showOvertime',
  POST_OVERTIME: 'postOvertime',
  POST_OVERTIME_FOR_MFBC: 'postOvertimeForMFBC',
  PUT_OVERTIME: 'updateOvertime',
  DELETE_OVERTIME: 'deleteOvertime',
  /*Overtime Request*/
  GET_OVERTIME_REQUEST: 'getOvertimeRequest',
  GET_OVERTIME_REQUEST_FOR_MFBC: 'getOvertimeRequestForMFBC',
  SHOW_OVERTIME_REQUEST: 'showOvertimeRequest',
  SHOW_OVERTIME_REQUEST_FOR_MFBC: 'showOvertimeRequestForMFBC',
  ACCEPT_OVERTIME_REQUEST: 'acceptOvertimeRequest',
  ACCEPT_OVERTIME_REQUEST_FOR_MFBC: 'acceptOvertimeRequestForMFBC',
  REJECT_OVERTIME_REQUEST: 'rejectOvertimeRequest',
  REJECT_OVERTIME_REQUEST_FOR_MFBC: 'rejectOvertimeRequestForMFBC',
  /*Undertime */
  GET_UNDERTIME: 'getUndertime',
  SHOW_UNDERTIME: 'showUndertime',
  POST_UNDERTIME: 'postUndertime',
  POST_UNDERTIME_FOR_MFBC: 'postUndertimeForMFBC',
  PUT_UNDERTIME: 'updateUndertime',
  DELETE_UNDERTIME: 'deleteUndertime',
  /*Undertime Request*/
  GET_UNDERTIME_REQUEST: 'getUndertimeRequest',
  GET_UNDERTIME_REQUEST_FOR_MFBC: 'getUndertimeRequestForMFBC',
  SHOW_UNDERTIME_REQUEST: 'showUndertimeRequest',
  SHOW_UNDERTIME_REQUEST_FOR_MFBC: 'showUndertimeRequestForMFBC',
  ACCEPT_UNDERTIME_REQUEST: 'acceptUndertimeRequest',
  ACCEPT_UNDERTIME_REQUEST_FOR_MFBC: 'acceptUndertimeRequestForMFBC',
  REJECT_UNDERTIME_REQUEST: 'rejectUndertimeRequest',
  REJECT_UNDERTIME_REQUEST_FOR_MFBC: 'rejectUndertimeRequestForMFBC',
  /*Official */
  GET_OFFICIAL: 'getOfficial',
  SHOW_OFFICIAL: 'showOfficial',
  POST_OFFICIAL: 'postOfficial',
  POST_OFFICIAL_FOR_JVD: 'postOfficialForJVD',
  POST_OFFICIAL_FOR_MFBC: 'postOfficialForMFBC',
  PUT_OFFICIAL: 'updateOfficial',
  DELETE_OFFICIAL: 'deleteOfficial',
  /*Official Request*/
  GET_OFFICIAL_REQUEST: 'getOfficialRequest',
  GET_OFFICIAL_REQUEST_FOR_MFBC: 'getOfficialRequestForMFBC',
  SHOW_OFFICIAL_REQUEST: 'showOfficialRequest',
  SHOW_OFFICIAL_REQUEST_FOR_MFBC: 'showOfficialRequestForMFBC',
  ACCEPT_OFFICIAL_REQUEST: 'acceptOfficialRequest',
  ACCEPT_OFFICIAL_REQUEST_FOR_MFBC: 'acceptOfficialRequestForMFBC',
  REJECT_OFFICIAL_REQUEST: 'rejectOfficialRequest',
  REJECT_OFFICIAL_REQUEST_FOR_MFBC: 'rejectOfficialRequestForMFBC',
  /*Certificate */
  GET_CERTIFICATE: 'getCertificate',
  SHOW_CERTIFICATE: 'showCertificate',
  POST_CERTIFICATE: 'postCertificate',
  POST_CERTIFICATE_FOR_JVD: 'postCertificateForJVD',
  POST_CERTIFICATE_FOR_MFBC: 'postCertificateForMFBC',
  UPLOAD_FILE_CERTIFICATE_FOR_JVD: 'uploadFileForCertificateForJVD',
  PUT_CERTIFICATE: 'updateCertificate',
  DELETE_CERTIFICATE: 'deleteCertificate',
  REQUEST_CERTIFICATE: 'getCertificateRequest',
  /*Shift Swap*/
  GET_CHANGE_SCHEDULE: 'getChangeSchedule',
  GET_CHANGE_SCHEDULE_REQUEST: 'getChangeScheduleRequest',
  POST_SHIFT_SWAP: 'postShiftSwap',
  SHOW_SHIFT_SWAP_REQUEST: 'showShiftSwapRequest',
  ACCEPT_SHIFT_SWAP_REQUEST: 'acceptShiftSwapRequest',
  REJECT_SHIFT_SWAP_REQUEST: 'rejectShiftSwapRequest',
  DELETE_SHIFT_SWAP: 'deleteShiftSwap',
  /*Setup */
  SHOW_GENERAL_SETUP: 'getGeneralSetup',
  UPDATE_GENERAL_SETUP: 'updateGeneralSetup',
  GET_DATE_FORMAT: 'getDateFormat',
  GET_TIME_FORMAT: 'getTimeFormat',
  GET_CLIENT_PROFILE: 'getClientProfile',
  GET_GRACE_TIME_PERIOD: 'getGraceTimePeriod',
  GET_GRACE_TIME_PERIOD_OUT: 'getGraceTimePeriodOut',
  GET_PAYROLL_TABS: 'getPayrollTabs',
  GET_PASSWORD_REQUIREMENTS: 'getPasswordRequirements',
  GET_PAYSLIP_LOGO: 'getPayslipLogo',
  GET_COMPANY_NAME: 'getCompanyName',
  GET_CUSTOM_FIELDS: 'getCustomFields',
  GET_ATTENDANCE_EDIT_SETUP: 'getAttendanceEditSetup',
  GET_TEAM_SETUP: 'getTeamSetup',
  GET_CHECKIN_SETUP: 'getCheckInSetup',
  GET_CHECKIN_ENABLED: 'getCheckInEnabled',
  GET_ATTENDANCE_ENABLED: 'getAttendanceEnabled',
  GET_SCREENSHOT_SETTINGS: 'getScreenshotSettings',
  UPDATE_SCREENSHOT_SETTINGS: 'updateScreenshotSettings',
  GET_EMPLOYEES_SCREENSHOT: 'getAttendanceScreenshotEmployees',
  POST_EMPLOYEE_SCREENSHOT: 'postEmployeeScreenshot',
  DELETE_EMPLOYEE_SCREENSHOT: 'deleteEmployeeScreenshot',
  UPDATE_EMPLOYEE_SCREENSHOT: 'updateEmployeeScreenshot',
  GET_ATTENDANCE_SETTINGS_BY_EMPLOYEE: 'getAttendanceSettingsByEmployee',
  /*Government Deductions*/
  GET_ALL_CUSTOM_FIELDS: 'getAllCustomFields',
  GET_WITHHOLDING_TAX_SETUP: 'getWithholdingTaxSetup',
  GET_WITHHOLDING_TAX_SETUP_BY_ID: 'getWithholdingTaxSetupByID',
  UPDATE_WITHHOLDING_TAX_SETUP: 'updateWithholdingTax',
  GET_PAGIBIG: 'getPagibig',
  GET_PHILHEALTH: 'getPhilhealth',
  GET_SSS: 'getSSS',
  /*Certificate Request*/
  GET_CERTIFICATE_REQUEST: 'getCertificateRequest',
  GET_CERTIFICATE_REQUEST_FOR_MFBC: 'getCertificateRequestForMFBC',
  SHOW_CERTIFICATE_REQUEST: 'showCertificateRequest',
  SHOW_CERTIFICATE_REQUEST_FOR_MFBC: 'showCertificateRequestForMFBC',
  ACCEPT_CERTIFICATE_REQUEST: 'acceptCertificateRequest',
  ACCEPT_CERTIFICATE_REQUEST_FOR_MFBC: 'acceptCertificateRequestForMFBC',
  REJECT_CERTIFICATE_REQUEST: 'rejectCertificateRequest',
  REJECT_CERTIFICATE_REQUEST_FOR_MFBC: 'rejectCertificateRequestForMFBC',
  /*Generate Payroll*/
  GET_PAYROLL_BY_PAYROLL_RANGE_TYPE: 'getAllPayrollByPayrollRangeType',
  GET_PAYROLL_WEEKLY: 'getPayrollWeekly',
  GET_PAYROLL_BIWEEKLY: 'getPayrollBiWeekly',
  GET_PAYROLL_SEMIMONTHLY: 'getPayrollSemiMonthly',
  GET_PAYROLL_MONTHLY: 'getPayrollMonthly',
  GENERATE_PAYROLL: 'generatePayroll',
  GENERATE_PAYROLL_FOR_JVD: 'generatePayrollForJVD',
  GENERATE_PAYROLL_FOR_MFBC: 'generatePayrollForMFBC',
  GENERATE_PAYROLL_FOR_STANDARD: 'generatePayrollForStandard',
  AUTO_GENERATE_PAYROLL : 'autoGeneratePayroll',
  GET_SALARIES: 'getSalaries',
  GET_SALARIES_FOR_JVD: 'getSalariesForJVD',
  GET_SALARIES_FOR_MFBC: 'getSalariesForMFBC',
  POST_DEDUCTION_PAYROLL: 'addDeductionOnSalary',
  POST_ALLOWANCE_PAYROLL: 'addAllowanceOnSalary',
  SHOW_PAYSLIP: 'showPayslip',
  EXPORT_PAYROLL: 'downloadPayroll',
  EXPORT_PAYROLL_FOR_JVD: 'downloadPayrollForJVD',
  EXPORT_PAYROLL_FOR_MFBC: 'downloadPayrollForMFBC',
  GET_ALL_SALARIES: 'getAllSalaries',
  GET_ALL_SALARIES_FOR_JVD: 'getAllSalariesForJVD',
  GENERATE_PAYROLL2: 'generatePayroll2',
  CREATE_PAYROLL_RANGE: 'createPayrollRange',
  REGENERATE_PAYROLL_FOR_JVD: 'regeneratePayrollForJVD',
  GET_PAYSLIPS: 'getPayslips',
  /*Work Site*/
  GET_WORK_SITE: 'getWorkSite',
  POST_WORK_SITE: 'postWorkSite',
  PUT_WORK_SITE: 'updateWorkSite',
  SHOW_WORK_SITE: 'showWorkSite',
  DELETE_WORK_SITE: 'deleteWorkSite',
  ALL_WORK_SITE: 'getAllWorkSite',
  GET_SUB_WORK_SITE: 'getSubworksite',
  GET_SUB_WORK_SITE_BY_WORK_SITE: 'getSubworksiteByWorksite',
  GET_WORK_SITE_NO_AUTH: 'getWorksiteNoAuth',
  GET_SHIFT_BASED_ON_WORKSITE_NO_AUTH: 'getShiftBasedOnWorksiteNoAuth',
  /*Hours Shift*/
  ALL_HOURS_SHIFT: 'getHoursShift',
  POST_HOURS_SHIFT: 'postHoursShift',
  PUT_HOURS_SHIFT: 'putHoursShift',
  DELETE_HOURS_SHIFT: 'deleteHoursShift',
  SHOW_HOURS_SHIFT: 'showHoursShift',
  SHOW_HOURS_SHIFT_BY_TYPE: 'getHoursShiftByType',
  GET_HOURS_SHIFT_BY_WORKSITE: 'getHoursShiftByWorksite',
  GET_HOURS_SHIFT_EIGHT_HOURS: 'getHoursShiftEightHours',
  GET_HOURS_SHIFT_BY_BRANCH: 'getHoursShiftByBranch',
  /*Schedule*/
  SET_SCHEDULE: 'setSchedule',
  RESET_SCHEDULE: 'resetSchedule',
  GET_EMPLOYEES_SCHEDULE_ASSIGNED: 'getEmployeesScheduleAssigned',
  GET_EMPLOYEE_SCHEDULE_BASED_ON_DATE: 'getEmployeeScheduleAssignedBasedOnDate',
  /*SearchedFeature*/
  SEARCH_FEATURE: 'searchedFeature',
  /*ACTIVITY LOG*/
  GET_ACTIVITY_LOG: 'getActivityLog',
  /*ADJUSTMENT*/
  POST_ADJUSTMENT: 'postAdjustment',
  GET_ADJUSTMENTS: 'getAdjustments',
  SHOW_ADJUSTMENT: 'showAdjustment'
}

const MESSAGE = (name: any) => {
  return ({
    CREATED:  `${name} successfully created.`,
    UPDATED:  `${name} successfully updated.`,
    DELETED:  `${name} successfully deleted.`,
    ACCEPTED: `${name} successfully accepted.`,
    REJECTED: `${name} successfully rejected.`,
  })
}

export const SUCCESS_MESSAGE = {
  EMPLOYEE: MESSAGE('Employee'),
  CANDIDATE: MESSAGE('Candidate'),
  BRANCH: MESSAGE('Branch'),
  DESIGNATION: MESSAGE('Designation'),
  JOB_LEVEL: MESSAGE('Job Level'),
  SHIFT_NAME: MESSAGE('Shift Name'),
  DEPARTMENT: MESSAGE('Department'),
  TEAM: MESSAGE('Team'),
  DEVICE: MESSAGE('Device'),
  WHITE_LISTED: MESSAGE('Whitelisted'),
  ROLE: MESSAGE('Role'),
  NOTICE: MESSAGE('Notice'),
  HOLIDAY: MESSAGE('Holiday'),
  HOLIDAY_TYPE: MESSAGE('Holiday type'),
  ATTENDANCE: MESSAGE('Attendance'),
  LEAVE_CONFIGURATION: MESSAGE('Leave configuration'),
  LEAVE_APPLICATION: MESSAGE('Leave application'),
  LEAVE_APPROVAL: MESSAGE('Leave approval'),
  WORK_SHIFT: MESSAGE('Work shift'),
  OVERTIME: MESSAGE('Overtime'),
  UNDERTIME: MESSAGE('Undertime'),
  OFFICIAL: MESSAGE('Official'),
  CERTIFICATE: MESSAGE('Certificate'),
  SHIFT_SWAP: MESSAGE('Shift Swap'),
  PAYGRADE: MESSAGE('Pay Grade'),
  DEDUCTION: MESSAGE('Deduction'),
  ALLOWANCE: MESSAGE('Allowance'),
  EMPLOYEE_SUCCESSFULLY_UPLOAD: 'Successfully Bulk Upload.',
  HOURS_SHIFT: MESSAGE('Hours Shift'),
  SCHEDULE: MESSAGE('Schedule'),
  PASSWORD: MESSAGE('Password'),
  ADJUSTMENT: MESSAGE('Adjustment')
}

export const ERROR_MESSAGE = {
  required: 'required field',
  max: 'too long!',
  phone: 'invalid phone number',
  email: 'invalid email',
  password_confirmation: 'passwords do not match',
  SYSTEM_ERROR: 'There is something wrong',
  device_not_allowed: 'Unauthorized Device. Please contact your administrator.',
  device_still_pending: 'Your device is still pending. Please contact your administrator.',
  leave_application_exist: 'Error: Leave application already submitted for the selected dates.',
  required_address: 'Location is required.',
  required_image: 'Image is required and must be valid.'
}

export const ATTENDANCE = {
  TIMEIN: 'Time In Successfully',
  TIMEOUT: 'Time Out Successfully',
  BREAKIN: 'Break In Successfully',
  BREAKOUT: 'Break Out Successfully',
  UPDATE_ATTENDANCE: 'Successfully Updated Attendance',
  CHECKIN: 'Check In Successfully'
}

export const LOGIN = {
  error: 'Username and password are required.',
}

export const VALIDATION = {
  required: 'required field',
  max: 'too long!',
  phone: 'invalid phone number',
  email: 'invalid email',
  password_confirmation: 'passwords do not match',
}

export const ROLE_PERMISSION = {
  SUCCESS: 'Role permission updated successfully.'
}

export const PAYROLL_COMPUTATION = {
  SUCCESSFULLY_ADDED: ' is successfully added.',
  SUCCESSFULLY_USED: 'Successfully Updated.',
  PAYROLL_SUCCESSFULLY_ADDED: 'Payroll Range Successfully Added.',
  SUCCESSFULLY_ASSIGNED: 'Successfully Assigned.',
  SUCCESSFULLY_TRANSFERRED: 'Successfully Transferred.',
  PAYROLL_SUCCESSFULLY_UPDATED: 'Payroll Range Successfully Updated.',
  SUCCESSFULLY_GENERATED: 'Successfully Generated.',
  SUCCESSFULLY_GENERATED_PAYROLL_RANGE: 'Successfully Generated Payroll Range.',
  SUCCESSFULLY_CREATED_PAYROLL_RANGE: 'Successfully Created Payroll Range.'
}

export const SETUP = {
  SUCCESSFULLY_TRANSFERRED: 'Successfully Updated.',
}

export const TEXT = {
  NOT_FOUND: 'No data found.',
}

export const WHITE_LISTED = {
  SUCCESS: 'Successfully Added as Whitelisted.'
}

export const IMAGE_BASE_URL : string = (process.env.REACT_APP_IMAGE_URL as string);
