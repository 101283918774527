import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Stack,
  Table,
  Carousel
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Avatar } from '@mui/material'
import Accept from 'asset/SvgComponent/Accept'
import Reject from 'asset/SvgComponent/Reject'
import View from 'asset/SvgComponent/View'
import Calendar from 'asset/SvgComponent/Calendar'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { IList, IItem, ILeaveRequest } from './types'
import { identical } from 'ramda'
import moment from "moment"
import AcceptDisabled from "asset/SvgComponent/AcceptDisabledIcon";
import RejectDisabled from "asset/SvgComponent/RejectDisabledIcon";
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {Formik} from "formik";
import EditIcon from "asset/SvgComponent/EditIcon";
import Delete from "asset/SvgComponent/Delete";
import {validationSchema} from "../../LeaveApplication/Retail/validationSchema";
import Select from "components/ReactSelect";
import {ILeaveCredit, ILeaveType} from "../../LeaveApplication/Retail/types";
import {IEmployee} from "../../../Attendance/AttendanceReport/Construction/types";
import DownloadWhite from "asset/SvgComponent/DownloadWhite";
import FileSaver from "file-saver";
import {faClose} from "@fortawesome/free-solid-svg-icons/faClose";

export default function Index() {
  const [key, setKey] = useState('pending')

  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [currentPage1, setCurrentPage1] = useState(0)
  const [lastPage1, setLastPage1] = useState(0)
  const [itemsPerPage1, setPerPage1] = useState(10)
  const [itemsCount1, setItemCount1] = useState(0)
  const [from1, setFrom1] = useState(0)

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [showCreate, setShowCreate] = useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)

  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);

  const [showConfirm, setShowConfirm] = useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      leave_application_id: id,
      application_from_date: '',
      application_to_date: '',
      application_date: '',
      number_of_day: '',
      purpose: '',
      approvers_order: 0,
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      leave_type: {
        leave_type_id: 0,
        leave_type_name: '',
        leave_description: ''
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: "",
        approvers_order: ""
      }],
      created_by_first_name: "",
      created_by_last_name: "",
      remarks: "",
      status: 0,
      rejected_by_first_name: "",
      rejected_by_last_name: ""
    })
  }

  const [showAccept, setShowAccept] = useState(false)
  const handleCloseAccept = () => setShowAccept(false)
  const handleShowAccept = (id: any) => {
    setShowAccept(true)
    setItem({
      leave_application_id: id,
      application_from_date: '',
      application_to_date: '',
      application_date: '',
      number_of_day: '',
      purpose: '',
      approvers_order: 0,
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      leave_type: {
        leave_type_id: 0,
        leave_type_name: '',
        leave_description: ''
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: "",
        approvers_order: ""
      }],
      created_by_first_name: "",
      created_by_last_name: "",
      remarks: "",
      status: 0,
      rejected_by_first_name: "",
      rejected_by_last_name: ""
    })
  }

  const [showReject, setShowReject] = useState(false)
  const handleCloseReject = () => {
    setShowReject(false)
    setReasonRejection("")
  }
  const handleShowReject = (id: any) => {
    setShowReject(true)
    setItem({
      leave_application_id: id,
      application_from_date: '',
      application_to_date: '',
      application_date: '',
      number_of_day: '',
      purpose: '',
      approvers_order: 0,
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      leave_type: {
        leave_type_id: 0,
        leave_type_name: '',
        leave_description: ''
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: "",
        approvers_order: ""
      }],
      created_by_first_name: "",
      created_by_last_name: "",
      remarks: "",
      status: 0,
      rejected_by_first_name: "",
      rejected_by_last_name: ""
    })
  }

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const [requests, setRequests] = useState<IList[]>([])
  const [requestsCompleted, setRequestsCompleted] = useState<IList[]>([])
  const [pendings, setPendings] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    leave_application_id: 0,
    application_from_date: '',
    application_to_date: '',
    application_date: '',
    number_of_day: '',
    purpose: '',
    approvers_order: 0,
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    leave_type: {
      leave_type_id: 0,
      leave_type_name: '',
      leave_description: ''
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      level: "",
      approvers_order: ""
    }],
    created_by_first_name: "",
    created_by_last_name: "",
    remarks: "",
    status: 0,
    rejected_by_first_name: "",
    rejected_by_last_name: ""
  })
  const [dateFormat, setDateFormat] = useState('')
  const [searched, setSearched] = useState("")
  const [searchedCompleted, setSearchedCompleted] = useState("")
  const [dateFilterPending, setDateFilterPending] = useState({dateFrom: '', dateTo: ''})
  const [dateFilterCompleted, setDateFilterCompleted] = useState({dateFrom: '', dateTo: ''})
  const [status, setStatus] = useState(0);
  const [reasonRejection, setReasonRejection] = useState("");

  const [leaveTypes, setLeaveTypes] = useState<ILeaveType[]>([
    {
      leave_type_id: 0,
      leave_type_name: '',
      leave_description: ''
    }
  ])

  const [leaveCredit, setLeaveCredit] = useState<ILeaveCredit>(
      {
        id: 0,
        employee_id: 0,
        leave_type: 0,
        credits: "",
        is_unlimited: 0
      }
  )
  const [showHourly, setShowHourly] = useState(true)

  const handleDisplayDays = (day: string|null) => {
    const dayFormat = parseFloat(day ?? "0")
    if(dayFormat > 1){
      return `${dayFormat} days`
    }else{
      return `${dayFormat} day`
    }
  }

  const getNumberOfDays = (startDate: any, endDate: any) => {
    //define two date object variables with dates inside it
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    //calculate time difference
    const time_difference = date2.getTime() - date1.getTime();

    //calculate days difference by dividing total milliseconds in a day
    return (time_difference / (1000 * 60 * 60 * 24)) + 1;
  };

  const handleChangeDropdownEmployee = (selectedOption: any) => {
    setSelectedEmployeeOption(selectedOption)
  }

  const _chunk = (arr: any, n: any) => {
    const group = [];
    for (let i = 0, end = arr.length / n; i < end; ++i)
      group.push(arr.slice(i * n, (i + 1) * n));
    return group;
  }

  const showLeaveBalance = async (id: null | number) => {
    const res = await axios.get<IAPI_Response<ILeaveCredit>>(API_ENDPOINT.SHOW_LEAVE_CREDITS +'/'+ id+'/'+selectedEmployeeOption.value)
    const result = await res
    if (result?.data?.results) {
      setLeaveCredit(result?.data?.results)
    }
  }

  const handleChangeSearchedKeyword = (e:any) => {
    setSearched(e.target.value)

    getLeaveRequest(null, e.target.value, status, dateFilterPending.dateFrom, dateFilterPending.dateTo)
  }

  const handleChangeSearchedKeywordCompleted = (e:any) => {
    setSearchedCompleted(e.target.value)

    getLeaveRequest(null, e.target.value, 1, dateFilterCompleted.dateFrom, dateFilterCompleted.dateTo)
  }

  const handleDisableAcceptRejectButton = (item: any) => {
    if(item.status == '3' || item.status == '2'){
      return true
    }else if(item.current == '0' || item.current == '2'){
      return true
    }else{
      return false
    }

  }

  const filterLeaveRequestPending = async(values: any) => {
    setDateFilterPending({dateFrom: values.date_from, dateTo: values.date_to})
    getLeaveRequest(null, searched, values.status, values.date_from, values.date_to)
  }

  const filterLeaveRequestCompleted = async(values: any) => {
    setDateFilterCompleted({dateFrom: values.date_from, dateTo: values.date_to})
    getLeaveRequest(null, searchedCompleted, 1, values.date_from, values.date_to)
  }

  const handleExportPendingLeave = async () => {
    try {
      const response = await axios.get(API_ENDPOINT.EXPORT_LEAVE_APPROVAL, {
        params: {
          status: status,
          date_from: dateFilterPending.dateFrom,
          date_to: dateFilterPending.dateTo
        },
        headers: {
          'Content-Type': 'blob'
        },
        responseType: 'arraybuffer'
      })
      const outputFilename = `Leave_Approvals_${moment().format('YYYY-MM-DD')}.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();

      FileSaver.writeFileSync(outputFilename, response.data);

    } catch {
      // setShowError(true)
      // setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

  }

  const handleExportApprovedLeave = async () => {
    try {
      const response = await axios.get(API_ENDPOINT.EXPORT_LEAVE_APPROVAL, {
        params: {
          status: 1,
          date_from: dateFilterCompleted.dateFrom,
          date_to: dateFilterCompleted.dateTo
        },
        headers: {
          'Content-Type': 'blob'
        },
        responseType: 'arraybuffer'
      })
      const outputFilename = `approved_leave_applications.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();

      FileSaver.writeFileSync(outputFilename, response.data);

    } catch {
      // setShowError(true)
      // setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
  }

  const postLeaveApplication = async (value: any, {resetForm} : any) => {

    if(leaveCredit.is_unlimited == 1 || (leaveCredit.is_unlimited == 0 && leaveCredit.credits && parseFloat(leaveCredit.credits) > 0)){

      if(value.employee_id != 0){
        await axios.post(API_ENDPOINT.POST_LEAVE_APPLICATION_FOR_MFBC, {
          leave_type_id: value.leave_type_id,
          application_from_date: value.application_from_date,
          application_to_date: value.application_to_date,
          number_of_day: value.number_of_day,
          purpose: value.purpose,
          isHalfOrWholeDay: value.isHalfOrWholeDay,
          employee_id: value.employee_id
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.LEAVE_APPLICATION.CREATED)
            getLeaveRequest(null, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo);
          }
          resetForm({})
          handleCloseCreate();
        }).catch((err) => {
          setShowError(true)
          if(err.response.data.message == "application_exist"){
            setError(ERROR_MESSAGE.leave_application_exist)
          }else{
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
          }
        });
      }else{
        setError("Please select employee.")
        setShowError(true)
      }

    }else{
      setError("You have insufficient leave credit balance.")
      setShowError(true)
    }


    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getLeaveType = async () => {
    const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_LEAVE_CONFIGURATION)
    const result = await res
    if (result?.data?.results?.length) {
      setLeaveTypes(result?.data?.results)
    }
  }

  const getAllEmployee = async () => {
    try {
      const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
      const result = await res
      if (result?.data?.results?.length) {
        const employees = result?.data?.results;
        if(JSON.parse(localStorage.getItem('role') ?? "") != "Admin" &&
            JSON.parse(localStorage.getItem('role') ?? "") != "Supervisor"){
          employees.map(employee => {
            if(employee.employee_id == Number(localStorage.getItem('employee_id'))){
              setSelectedEmployeeOption({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
              // setDisableSelect(true)
              setEmployeeOptions([])
              setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
              setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
            }
          })


        }else{
          setEmployeeOptions([])
          setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
          employees.map(employee => {
            setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
          })
        }

      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getLeaveRequest = async (page_number: null | number, searchedKeyword: string, status: number, dateFrom: string, dateTo: string) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<ILeaveRequest>>(API_ENDPOINT.GET_LEAVE_REQUEST_FOR_MFBC, {
        params: {
          limit: 10,
          page: page_number,
          searched: searchedKeyword,
          status: status,
          date_from: dateFrom,
          date_to: dateTo
        },
      })
      const result = await response
      // if(status == 0){
        if (result?.data?.results?.data?.length) {
          setRequests(result?.data?.results?.data)
          setCurrentPage(result?.data?.results?.current_page)
          setLastPage(result?.data?.results?.last_page)
          setPerPage(result?.data?.results?.per_page)
          setItemCount(result?.data?.results?.total)
          setFrom(result?.data?.results?.from)
        }else{
          setRequests([])
        }
      // }else{
      //   if (result?.data?.results?.data?.length) {
      //     setRequestsCompleted(result?.data?.results?.data)
      //     setCurrentPage1(result?.data?.results?.current_page)
      //     setLastPage1(result?.data?.results?.last_page)
      //     setPerPage1(result?.data?.results?.per_page)
      //     setItemCount1(result?.data?.results?.total)
      //     setFrom1(result?.data?.results?.from)
      //   }else{
      //     setRequestsCompleted([])
      //   }
      // }

      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const pendingLeaveRequest = async () => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IList[]>>(API_ENDPOINT.GET_ALL_PENDING_LEAVE_REQUESTS)
      const result = await response
      if (result?.data?.results?.length) {
        setPendings(result?.data?.results)
      }else{
        setPendings([])
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showLeaveRequest = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_LEAVE_REQUEST_FOR_MFBC +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
      }
      handleShow()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const acceptLeaveRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.ACCEPT_LEAVE_REQUEST_FOR_MFBC +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.LEAVE_APPROVAL.ACCEPTED)
          getLeaveRequest(currentPage, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo);
          // getLeaveRequest(currentPage, searchedCompleted, 1, dateFilterCompleted.dateFrom, dateFilterCompleted.dateTo);
          pendingLeaveRequest()
       }
       handleCloseAccept();
       handleClose();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const rejectLeaveRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.REJECT_LEAVE_REQUEST_FOR_MFBC +'/'+ id, {
      reason_of_rejection: reasonRejection
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.LEAVE_APPROVAL.REJECTED)
         getLeaveRequest(currentPage, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo);
         // getLeaveRequest(currentPage, searchedCompleted, 1, dateFilterCompleted.dateFrom, dateFilterCompleted.dateTo);
         pendingLeaveRequest()
       }
       handleCloseReject();
       handleCloseAccept();

       handleClose();
       setReasonRejection("")
      console.log("end")
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const deleteLeaveApplication = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_LEAVE_APPLICATION +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.LEAVE_APPLICATION.DELETED)
        getLeaveRequest(currentPage, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo)
        // getLeaveRequest(currentPage1, searchedCompleted, 1, dateFilterCompleted.dateFrom, dateFilterCompleted.dateTo)
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getLeaveRequest(null, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo)
      // getLeaveRequest(null, searchedCompleted, 1, dateFilterCompleted.dateFrom, dateFilterCompleted.dateTo)
      pendingLeaveRequest()
      setDateFormat(localStorage.getItem('date_format') ?? "")
      getAllEmployee()
      getLeaveType()
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Leave Approvals</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/leaves/holiday-management'>
            Leaves
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Leave Approvals</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        {/*<Tabs*/}
        {/*    variant={'tabs'}*/}
        {/*    className='tabs'*/}
        {/*    id='controlled-tab-example'*/}
        {/*    activeKey={key}*/}
        {/*    onSelect={(k: any) => {*/}
        {/*      setKey(k)*/}
        {/*    }}*/}
        {/*    // sx={{*/}
        {/*    //   opacity: open ? 1 : 0,*/}
        {/*    //   fontWeight: openDropdown === 0 ? 700 : 100,*/}
        {/*    // }}*/}
        {/*>*/}
        {/*  <Tab  eventKey='pending' title='Pending'>*/}
            <Card className='border-0  p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <Formik
                        initialValues={
                          {
                            status: '',
                            date_from: '',
                            date_to: ''
                          }
                        }
                        onSubmit={filterLeaveRequestPending}
                    >
                      {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                              <Col sm={2}>
                                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                  <Form.Label className='fw-bold form-labels'>Status</Form.Label>
                                  <Form.Select
                                      size={'sm'}
                                      name="status"
                                      value={values.status}
                                      onChange={(e: any) => {
                                        handleChange(e);
                                        handleSubmit();
                                        setStatus(e.target.value)
                                      }}
                                      onBlur={handleBlur}
                                      isInvalid={touched.status && !!errors.status}
                                  >
                                    <option value="0">All Status</option>
                                    <option value="1">Pending</option>
                                    <option value="3">Approved</option>
                                    <option value="2">Disapproved</option>
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.status}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                  <Form.Label className='fw-bold form-labels'>Date From</Form.Label>
                                  <InputGroup className='mb-2 ' size={'sm'}>
                                    <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                      <Calendar/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        size={'sm'}
                                        type='date'
                                        placeholder='Friday, June 03, 2022'
                                        name='date_from'
                                        value={values.date_from}
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          values.date_from = e.target.value
                                          handleSubmit();
                                        }}
                                        onBlur={handleBlur}
                                        isInvalid={touched.date_from && !!errors.date_from}
                                        // max={moment().format("YYYY-MM-DD")}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.date_from}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                  <Form.Label className='fw-bold form-labels'>Date To</Form.Label>
                                  <InputGroup className='mb-2 ' size={'sm'}>
                                    <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                      <Calendar/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        size={'sm'}
                                        type='date'
                                        placeholder='Friday, June 03, 2022'
                                        name='date_to'
                                        value={values.date_to}
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          values.date_to = e.target.value
                                          handleSubmit();
                                        }}
                                        onBlur={handleBlur}
                                        isInvalid={touched.date_to && !!errors.date_to}
                                        // max={moment().format("YYYY-MM-DD")}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.date_to}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              <Col sm={1}></Col>

                              <Col sm={5} className='d-flex justify-content-end hidden'>
                                <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                                  <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                  <br />
                                  <InputGroup size='sm' className={'w-100'}>
                                    <Form.Control
                                        aria-label='search'
                                        aria-describedby='inputGroup-sizing-sm'
                                        placeholder='Search...'
                                        onChange={handleChangeSearchedKeyword}
                                    />
                                    <InputGroup.Text className='bg-transparent search-input '>
                                      <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                  </InputGroup>
                                </Form.Group>
                                <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                                  <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                  <br />
                                  <Button
                                      onClick={handleExportPendingLeave}
                                      className=' add-new-btn rounded mx-3 text-white'
                                      variant='warning'
                                  >
                                    <DownloadWhite/>
                                    &nbsp; Export
                                  </Button>
                                </Form.Group>
                                {
                                  JSON.parse(localStorage.getItem('role') ?? "") == "Supervisor" ?
                                      <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                                        <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                        <br />
                                        <Button
                                            onClick={handleShowCreate}
                                            className=' add-new-btn rounded mx-2 text-white'
                                            variant='warning'
                                        >
                                          <EditIcon/>
                                          &nbsp;Apply for Leave
                                        </Button>{' '}
                                      </Form.Group>
                                      : ""
                                }

                              </Col>
                            </Row>
                          </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
                <Row><Col sm={12} className={'fw-title fw-bold'} style={{fontSize:'90%'}}>Pending Approvals</Col></Row>
                 <Carousel
                     className={'mb-2 custom-carousel'}
              variant="dark"
              interval={null}
              controls={pendings.length > 4 ? true : false}
              indicators={pendings.length > 4 ? true : false}
              >
              {pendings.length > 0 ? (
                _chunk(pendings, 4).map((pending: any, index: any) =>
                  <Carousel.Item key={index} className="px-4 pb-4">
                    <Row className='mt-2'>
                      {pending.map((item: any, key: any) =>
                        <Col sm={3} key={key} className="px-2 pb-2">
                          <Card className='shadow bordered-card border-0 '>
                            <Card.Body style={{ padding: 0 }}>
                              <Container fluid className='p-2'>

                                <Row style={{fontSize: '85%'}}>
                                  <Col sm={9}>
                                    <Stack direction='horizontal'>
                                      <div>
                                        {
                                          item.photo != "" ?
                                          <Avatar
                                              sx={{ width: 65, height: 65 }}
                                              src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                          ></Avatar> :
                                          <Avatar
                                              sx={{ width: 65, height: 65}}
                                              src={""}
                                          ></Avatar>
                                        }
                                      </div>
                                      <div className='mx-3'>
                                        &nbsp;{item.first_name} {item.last_name}
                                        <br />
                                        &nbsp;<span style={{fontSize: '90%'}} className='text-muted fw-light'>{item.role_name}</span>
                                      </div>

                                    </Stack>
                                  </Col>
                                  <Col className='align-content-end text-right' sm={3}>
                                    <Button
                                        onClick={() => showLeaveRequest(item.leave_application_id)}
                                        className=' p-0 pt-0 pb-0'
                                        style={{ fontSize: '12px', color: 'white', marginTop: '-50px' }}
                                        variant='btn-transparent'
                                        size='sm'
                                    >
                                      <View width={25} height={20}/>
                                    </Button>{' '}
                                  </Col>
                                </Row>
                                {/*<Table size='sm' borderless className='mt-1 mb-0'>*/}
                                  <Row className={'mb-1'} style={{fontSize: '75%'}}>
                                    <Col className='fw-bold' sm={4} >Leave Type:</Col>
                                    <Col className='fw-light' sm={8}>{item.leave_type_name}</Col>
                                  </Row>
                                  <Row className={'mb-2'} style={{fontSize: '75%'}}>
                                    <Col className='fw-bold' sm={4}>Applied On:</Col>
                                    <Col className='fw-light' sm={8}>{moment(new Date(item.application_date ?? "")).format(dateFormat)}</Col>
                                  </Row>
                                  <Row className={'mb-2'} style={{fontSize: '75%'}}>
                                    <Col className='fw-bold' sm={4}>Duration:</Col>
                                    <Col sm={8}>
                                      {moment(new Date(item.application_from_date ?? "")).format(dateFormat)} to {moment(new Date(item.application_to_date ?? "")).format(dateFormat)}
                                    </Col>
                                    {/*<Col sm={2}>to</Col>*/}
                                    {/*<Col sm={5}>*/}
                                    {/*  <Calendar/>&nbsp; {moment(new Date(item.application_to_date ?? "")).format(dateFormat)}*/}
                                    {/*</Col>*/}
                                  </Row >
                                  <Row className={'mb-1'} style={{fontSize: '75%'}}>
                                    <Col sm={4} className='fw-bold'>
                                      No. of Days:
                                    </Col>
                                    <Col sm={8} className='fw-light'>{item.number_of_day} day/s</Col>

                                  </Row>
                                  <Row className={'mb-1'} style={{fontSize: '75%'}}>
                                    <Col sm={4} className='fw-bold'>
                                      Status:
                                    </Col>
                                    <Col sm={8}>
                                      <Button
                                          size={'sm'}
                                          className='text-center add-new-btn rounded  text-white p-1 pt-0 pb-0'
                                          variant='secondary'
                                      >
                                        Pending
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row style={{fontSize: '75%'}}>
                                    <Col sm={4} className='fw-bold'>
                                      Reason:
                                    </Col>
                                    <Col sm={8} className='fw-light reason-text' title={item.purpose}>{item.purpose}</Col>
                                  </Row>

                                {/*</Table>*/}
                              </Container>

                              <Row size='sm' bordered className='mt-0 mb-0 p-2'>
                                <Col sm={6} className='text-center btn-leave-application'>
                                  <Button
                                      size={'sm'}
                                      // className='text-center fw-bold add-new-btn rounded'
                                      // variant='transparent'
                                      variant='outline-primary'
                                      // className='px-5 pe-5 mx-2'
                                      className={'p-4 pt-1 pb-1'}
                                      onClick={() => handleShowReject(item.leave_application_id)}
                                  >
                                    &nbsp;Disapprove&nbsp;
                                  </Button>{' '}
                                </Col>
                                  <Col sm={6} className='text-center btn-leave-application'>
                                      <Button
                                        size={'sm'}
                                        // style={{fontSize: '10px'}}
                                        // className='text-center fw-bold add-new-btn rounded text-primary'
                                        // variant='transparent'
                                        variant='primary'
                                        className='p-4 pt-1 pb-1 text-white'
                                        onClick={() => handleShowAccept(item.leave_application_id)}
                                      >
                                        Approve
                                      </Button>{' '}
                                  </Col>
                                  {/*<Col sm={2}></Col>*/}

                                {/*<Button*/}
                                {/*    onClick={() => rejectLeaveRequest(item.leave_application_id)}*/}
                                {/*    variant='outline-primary'*/}
                                {/*    className=' px-5 pe-5 mx-2'*/}
                                {/*    size={'sm'}*/}
                                {/*    disabled={handleDisableAcceptRejectButton(item)}*/}
                                {/*>*/}
                                {/*  Rejected*/}
                                {/*</Button>*/}
                                {/*<Button*/}
                                {/*    onClick={() => acceptLeaveRequest(item.leave_application_id)}*/}
                                {/*    variant='primary'*/}
                                {/*    className='text-white px-5 pe-5'*/}
                                {/*    size={'sm'}*/}
                                {/*    disabled={handleDisableAcceptRejectButton(item)}*/}
                                {/*>*/}
                                {/*  Approved*/}
                                {/*</Button>*/}
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </Carousel.Item>
                )
              ):(
                <Row>
                  <Col sm={12} className={'text-center'} style={{fontSize: '80%'}}>No Pending Approval yet.</Col>
                </Row>
              )}
            </Carousel>
            <br/>
                {/* <br/>
            <span className='fw-bold'>Leaves Summary</span> */}
                <Table responsive size='sm' className='bordered-tabs-top mt-3'>
                  <thead>
                  <tr className='text-center'>
                    <th>No.</th>
                    <th style={{width: '20%'}}>Employee Name</th>
                    <th>Leave Type</th>
                    <th>Date Applied</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Days</th>
                    <th style={{width: '20%'}}>Reason</th>
                    {/* <th>Remarks</th> */}
                    <th style={{ width: '50px' }}>Status</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <>
                    {requests.length > 0 ? (
                        requests.map((item, index) =>
                            <tr className='mb-2' key={index}>
                              <td>{from + index}</td>
                              <td>
                                <Stack direction='horizontal'>
                                  <div>
                                    {
                                      item.photo != "" ?
                                          <Avatar
                                              sx={{ width: 20, height: 20 }}
                                              src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                              className={"profile-avatar"}
                                          ></Avatar> :
                                          <Avatar
                                              sx={{ width: 20, height: 20}}
                                              src={""}
                                              className={"profile-avatar"}
                                          ></Avatar>
                                    }
                                  </div>
                                  <div>&nbsp;{item.first_name} {item.last_name}</div>
                                </Stack>
                              </td>
                              <td>{item.leave_type_name}</td>
                              <td className='text-center'>{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                              <td className='text-center'>{moment(new Date(item.application_from_date ?? "")).format(dateFormat)}</td>
                              <td className='text-center'>{moment(new Date(item.application_to_date ?? "")).format(dateFormat)}</td>
                              <td className='text-center'>{handleDisplayDays(item.number_of_day)}</td>
                              <td className='text-center display-reason' title={item.purpose ?? ""}>{item.purpose}</td>
                              {/* <td className='text-truncate table-responsive'>{item.remarks}</td> */}
                              <td className='text-center'>
                                <div className='d-grid'>
                                  <>
                                    {(() => {
                                      // Approved:2
                                      if (item.status && item.status == 3) {
                                        return (
                                            <Button
                                                className=" p-1 pt-0 pb-0"
                                                style={{fontSize: '12px', color: 'white'}}
                                                variant="success"
                                                size="sm"
                                            >
                                              Approved
                                            </Button>
                                        )
                                        // Reject:3
                                      } else if(item.status && item.status == 2) {
                                        return (
                                            <Button
                                                className=" p-1 pt-0 pb-0"
                                                style={{fontSize: '12px', color: 'white'}}
                                                variant="danger"
                                                size="sm"
                                            >
                                              Disapproved
                                            </Button>
                                        )
                                      }

                                      return (
                                          <Button
                                              className=" p-1 pt-0 pb-0"
                                              style={{fontSize: '12px', color: 'white'}}
                                              variant="secondary"
                                              size="sm"
                                          >
                                            Pending
                                          </Button>
                                      )

                                    })()}
                                  </>
                                </div>
                              </td>
                              <td className='text-center'>
                                <Button
                                    onClick={() => showLeaveRequest(item.leave_application_id)}
                                    className=' p-0 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant='btn-transparent'
                                    size='sm'
                                >
                                  <View/>
                                </Button>
                                <Button
                                    onClick={() => handleShowAccept(item.leave_application_id)}
                                    className='p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant='btn-transparent'
                                    size='sm'
                                    disabled={handleDisableAcceptRejectButton(item)}
                                >
                                  {
                                    handleDisableAcceptRejectButton(item) ?
                                        <AcceptDisabled/> : <Accept/>
                                  }
                                </Button>
                                <Button
                                    onClick={() => handleShowReject(item.leave_application_id)}
                                    className='p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant='btn-transparent'
                                    size='sm'
                                    disabled={handleDisableAcceptRejectButton(item)}
                                >
                                  {
                                    handleDisableAcceptRejectButton(item) ?
                                        <RejectDisabled/> : <Reject/>
                                  }
                                </Button>

                                {
                                    JSON.parse(localStorage.getItem('role') ?? "") == "Supervisor" ||
                                    JSON.parse(localStorage.getItem('role') ?? "") == "Admin" ?
                                        <Button
                                            onClick={() => handleShowConfirm(item.leave_application_id)}
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-transparent'
                                            size='sm'
                                        >
                                          <Delete/>
                                        </Button>
                                        : ""
                                }

                              </td>
                            </tr>
                        )
                    ) : (
                        <tr className='no-item'>
                          <td colSpan={10}>{TEXT.NOT_FOUND}</td>
                        </tr>
                    )}
                  </>
                  </tbody>
                </Table>

                <Pagination
                    itemsCount={itemsCount}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getLeaveRequest(page, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo);
                    }
                    }
                />




              </Card.Body>
            </Card>
        {/*  </Tab>*/}
        {/*  <Tab  eventKey='completed' title='Completed'>*/}
        {/*    <Card className='border-0  p-0 bordered-card-tabs'>*/}
        {/*      <Card.Body>*/}
        {/*        <Row>*/}
        {/*          <Col sm={12}>*/}
        {/*            <Formik*/}
        {/*                initialValues={*/}
        {/*                  {*/}
        {/*                    date_from: '',*/}
        {/*                    date_to: ''*/}
        {/*                  }*/}
        {/*                }*/}
        {/*                onSubmit={filterLeaveRequestCompleted}*/}
        {/*            >*/}
        {/*              {({*/}
        {/*                  values,*/}
        {/*                  errors,*/}
        {/*                  touched,*/}
        {/*                  handleChange,*/}
        {/*                  handleBlur,*/}
        {/*                  handleSubmit,*/}
        {/*                  isSubmitting*/}
        {/*                }) => (*/}
        {/*                  <Form noValidate onSubmit={handleSubmit}>*/}
        {/*                    <Row>*/}
        {/*                      <Col sm={2}>*/}
        {/*                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>*/}
        {/*                          <Form.Label className='fw-bold form-labels'>Date From</Form.Label>*/}
        {/*                          <InputGroup className='mb-2 ' size={'sm'}>*/}
        {/*                            <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>*/}
        {/*                              <Calendar/>*/}
        {/*                            </InputGroup.Text>*/}
        {/*                            <Form.Control*/}
        {/*                                size={'sm'}*/}
        {/*                                type='date'*/}
        {/*                                placeholder='Friday, June 03, 2022'*/}
        {/*                                name='date_from'*/}
        {/*                                value={values.date_from}*/}
        {/*                                onChange={(e: any) => {*/}
        {/*                                  handleChange(e);*/}
        {/*                                  values.date_from = e.target.value*/}
        {/*                                  handleSubmit();*/}
        {/*                                }}*/}
        {/*                                onBlur={handleBlur}*/}
        {/*                                isInvalid={touched.date_from && !!errors.date_from}*/}
        {/*                                max={moment().format("YYYY-MM-DD")}*/}
        {/*                            />*/}
        {/*                            <Form.Control.Feedback type="invalid">*/}
        {/*                              {errors.date_from}*/}
        {/*                            </Form.Control.Feedback>*/}
        {/*                          </InputGroup>*/}
        {/*                        </Form.Group>*/}
        {/*                      </Col>*/}
        {/*                      <Col sm={2}>*/}
        {/*                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>*/}
        {/*                          <Form.Label className='fw-bold form-labels'>Date To</Form.Label>*/}
        {/*                          <InputGroup className='mb-2 ' size={'sm'}>*/}
        {/*                            <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>*/}
        {/*                              <Calendar/>*/}
        {/*                            </InputGroup.Text>*/}
        {/*                            <Form.Control*/}
        {/*                                size={'sm'}*/}
        {/*                                type='date'*/}
        {/*                                placeholder='Friday, June 03, 2022'*/}
        {/*                                name='date_to'*/}
        {/*                                value={values.date_to}*/}
        {/*                                onChange={(e: any) => {*/}
        {/*                                  handleChange(e);*/}
        {/*                                  values.date_to = e.target.value*/}
        {/*                                  handleSubmit();*/}
        {/*                                }}*/}
        {/*                                onBlur={handleBlur}*/}
        {/*                                isInvalid={touched.date_to && !!errors.date_to}*/}
        {/*                                max={moment().format("YYYY-MM-DD")}*/}
        {/*                            />*/}
        {/*                            <Form.Control.Feedback type="invalid">*/}
        {/*                              {errors.date_to}*/}
        {/*                            </Form.Control.Feedback>*/}
        {/*                          </InputGroup>*/}
        {/*                        </Form.Group>*/}
        {/*                      </Col>*/}
        {/*                      <Col sm={4}></Col>*/}

        {/*                      <Col sm={4} className='d-flex justify-content-end hidden'>*/}
        {/*                        <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>*/}
        {/*                          <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>*/}
        {/*                          <br />*/}
        {/*                          <InputGroup size='sm' className={'w-100'}>*/}
        {/*                            <Form.Control*/}
        {/*                                aria-label='search'*/}
        {/*                                aria-describedby='inputGroup-sizing-sm'*/}
        {/*                                placeholder='Search...'*/}
        {/*                                onChange={handleChangeSearchedKeywordCompleted}*/}
        {/*                            />*/}
        {/*                            <InputGroup.Text className='bg-transparent search-input '>*/}
        {/*                              <FontAwesomeIcon icon={faSearch} />*/}
        {/*                            </InputGroup.Text>*/}
        {/*                          </InputGroup>*/}
        {/*                        </Form.Group>*/}
        {/*                        <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>*/}
        {/*                          <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>*/}
        {/*                          <br />*/}
        {/*                          <Button*/}
        {/*                              onClick={handleExportApprovedLeave}*/}
        {/*                              className=' add-new-btn rounded mx-3 text-white'*/}
        {/*                              variant='warning'*/}
        {/*                          >*/}
        {/*                            <DownloadWhite/>*/}
        {/*                            &nbsp; Export*/}
        {/*                          </Button>*/}
        {/*                        </Form.Group>*/}
        {/*                      </Col>*/}
        {/*                    </Row>*/}
        {/*                  </Form>*/}
        {/*              )}*/}
        {/*            </Formik>*/}
        {/*          </Col>*/}
        {/*        </Row>*/}

        {/*        <Table responsive size='sm' className='bordered-tabs-top mt-3'>*/}
        {/*          <thead>*/}
        {/*          <tr className='text-center'>*/}
        {/*            <th>No.</th>*/}
        {/*            <th style={{width: '20%'}}>Employee Name</th>*/}
        {/*            <th>Leave Type</th>*/}
        {/*            <th>Date Applied</th>*/}
        {/*            <th>From</th>*/}
        {/*            <th>To</th>*/}
        {/*            <th>Days</th>*/}
        {/*            <th style={{width: '20%'}}>Reason</th>*/}
        {/*            /!* <th>Remarks</th> *!/*/}
        {/*            <th style={{ width: '50px' }}>Status</th>*/}
        {/*            <th>Actions</th>*/}
        {/*          </tr>*/}
        {/*          </thead>*/}
        {/*          <tbody>*/}
        {/*          <>*/}
        {/*            {requestsCompleted.length > 0 ? (*/}
        {/*                requestsCompleted.map((item, index) =>*/}
        {/*                    <tr className='mb-2' key={index}>*/}
        {/*                      <td>{from1 + index}</td>*/}
        {/*                      <td>*/}
        {/*                        <Stack direction='horizontal'>*/}
        {/*                          <div>*/}
        {/*                            {*/}
        {/*                              item.photo != "" ?*/}
        {/*                                  <Avatar*/}
        {/*                                      sx={{ width: 20, height: 20 }}*/}
        {/*                                      src={process.env.REACT_APP_IMAGE_URL+ item.photo}*/}
        {/*                                      className={"profile-avatar"}*/}
        {/*                                  ></Avatar> :*/}
        {/*                                  <Avatar*/}
        {/*                                      sx={{ width: 20, height: 20}}*/}
        {/*                                      src={""}*/}
        {/*                                      className={"profile-avatar"}*/}
        {/*                                  ></Avatar>*/}
        {/*                            }*/}
        {/*                          </div>*/}
        {/*                          <div>&nbsp;{item.first_name} {item.last_name}</div>*/}
        {/*                        </Stack>*/}
        {/*                      </td>*/}
        {/*                      <td>{item.leave_type_name}</td>*/}
        {/*                      <td className='text-center'>{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>*/}
        {/*                      <td className='text-center'>{moment(new Date(item.application_from_date ?? "")).format(dateFormat)}</td>*/}
        {/*                      <td className='text-center'>{moment(new Date(item.application_to_date ?? "")).format(dateFormat)}</td>*/}
        {/*                      <td className='text-center'>{handleDisplayDays(item.number_of_day)}</td>*/}
        {/*                      <td className='text-center display-reason' title={item.purpose ?? ""}>{item.purpose}</td>*/}
        {/*                      /!* <td className='text-truncate table-responsive'>{item.remarks}</td> *!/*/}
        {/*                      <td className='text-center'>*/}
        {/*                        <div className='d-grid'>*/}
        {/*                          <>*/}
        {/*                            {(() => {*/}
        {/*                              // Approved:2*/}
        {/*                              if (item.status && item.status == 3) {*/}
        {/*                                return (*/}
        {/*                                    <Button*/}
        {/*                                        className=" p-1 pt-0 pb-0"*/}
        {/*                                        style={{fontSize: '12px', color: 'white'}}*/}
        {/*                                        variant="success"*/}
        {/*                                        size="sm"*/}
        {/*                                    >*/}
        {/*                                      Approved*/}
        {/*                                    </Button>*/}
        {/*                                )*/}
        {/*                                // Reject:3*/}
        {/*                              } else if(item.status && item.status == 2) {*/}
        {/*                                return (*/}
        {/*                                    <Button*/}
        {/*                                        className=" p-1 pt-0 pb-0"*/}
        {/*                                        style={{fontSize: '12px', color: 'white'}}*/}
        {/*                                        variant="danger"*/}
        {/*                                        size="sm"*/}
        {/*                                    >*/}
        {/*                                      Disapproved*/}
        {/*                                    </Button>*/}
        {/*                                )*/}
        {/*                              }*/}

        {/*                              return (*/}
        {/*                                  <Button*/}
        {/*                                      className=" p-1 pt-0 pb-0"*/}
        {/*                                      style={{fontSize: '12px', color: 'white'}}*/}
        {/*                                      variant="secondary"*/}
        {/*                                      size="sm"*/}
        {/*                                  >*/}
        {/*                                    Pending*/}
        {/*                                  </Button>*/}
        {/*                              )*/}

        {/*                            })()}*/}
        {/*                          </>*/}
        {/*                        </div>*/}
        {/*                      </td>*/}
        {/*                      <td className='text-center'>*/}
        {/*                        <Button*/}
        {/*                            onClick={() => showLeaveRequest(item.leave_application_id)}*/}
        {/*                            className=' p-0 pt-0 pb-0'*/}
        {/*                            style={{ fontSize: '12px', color: 'white' }}*/}
        {/*                            variant='btn-transparent'*/}
        {/*                            size='sm'*/}
        {/*                        >*/}
        {/*                          <View/>*/}
        {/*                        </Button>*/}
        {/*                        <Button*/}
        {/*                            onClick={() => handleShowAccept(item.leave_application_id)}*/}
        {/*                            className='p-1 pt-0 pb-0'*/}
        {/*                            style={{ fontSize: '12px', color: 'white' }}*/}
        {/*                            variant='btn-transparent'*/}
        {/*                            size='sm'*/}
        {/*                            disabled={handleDisableAcceptRejectButton(item)}*/}
        {/*                        >*/}
        {/*                          {*/}
        {/*                            handleDisableAcceptRejectButton(item) ?*/}
        {/*                                <AcceptDisabled/> : <Accept/>*/}
        {/*                          }*/}
        {/*                        </Button>*/}
        {/*                        <Button*/}
        {/*                            onClick={() => handleShowReject(item.leave_application_id)}*/}
        {/*                            className='p-1 pt-0 pb-0'*/}
        {/*                            style={{ fontSize: '12px', color: 'white' }}*/}
        {/*                            variant='btn-transparent'*/}
        {/*                            size='sm'*/}
        {/*                            disabled={handleDisableAcceptRejectButton(item)}*/}
        {/*                        >*/}
        {/*                          {*/}
        {/*                            handleDisableAcceptRejectButton(item) ?*/}
        {/*                                <RejectDisabled/> : <Reject/>*/}
        {/*                          }*/}
        {/*                        </Button>*/}


        {/*                        {*/}
        {/*                          JSON.parse(localStorage.getItem('role') ?? "") == "Supervisor" ||*/}
        {/*                          JSON.parse(localStorage.getItem('role') ?? "") == "Admin"?*/}
        {/*                              <Button*/}
        {/*                                  onClick={() => handleShowConfirm(item.leave_application_id)}*/}
        {/*                                  className=' p-1 pt-0 pb-0'*/}
        {/*                                  style={{ fontSize: '12px', color: 'white' }}*/}
        {/*                                  variant=' btn-transparent'*/}
        {/*                                  size='sm'*/}
        {/*                              >*/}
        {/*                                <Delete/>*/}
        {/*                              </Button>*/}
        {/*                              : ""*/}
        {/*                        }*/}
        {/*                      </td>*/}
        {/*                    </tr>*/}
        {/*                )*/}
        {/*            ) : (*/}
        {/*                <tr className='no-item'>*/}
        {/*                  <td colSpan={10}>{TEXT.NOT_FOUND}</td>*/}
        {/*                </tr>*/}
        {/*            )}*/}
        {/*          </>*/}
        {/*          </tbody>*/}
        {/*        </Table>*/}

        {/*        <Pagination*/}
        {/*            itemsCount={itemsCount1}*/}
        {/*            itemsPerPage={itemsPerPage1}*/}
        {/*            currentPage={currentPage1}*/}
        {/*            lastPage={lastPage1}*/}
        {/*            setCurrentPage={setCurrentPage1}*/}
        {/*            alwaysShown={false}*/}
        {/*            pageClicked={(page: number) => {*/}
        {/*              getLeaveRequest(page, searchedCompleted, 1, dateFilterCompleted.dateFrom, dateFilterCompleted.dateTo);*/}
        {/*            }*/}
        {/*            }*/}
        {/*        />*/}




        {/*      </Card.Body>*/}
        {/*    </Card>*/}
        {/*  </Tab>*/}
        {/*</Tabs>*/}
        <Dialog
            show={showAccept}
            setShow={handleCloseAccept}
            setEvent={() => {
              acceptLeaveRequest(item.leave_application_id)
            }
            }
            variant='accept'
            message='Please confirm to accept leave request?'
        />

        {/*<Dialog*/}
        {/*    show={showReject}*/}
        {/*    setShow={handleCloseReject}*/}
        {/*    setEvent={() => {*/}
        {/*      rejectLeaveRequest(item.leave_application_id)*/}
        {/*    }*/}
        {/*    }*/}
        {/*    variant='reject'*/}
        {/*    message='Do you really want to reject these leave request?'*/}
        {/*/>*/}

        {/* REJECTION CONFIRMATION MODAL */}
        <Modal className='modal' centered show={showReject} onHide={handleCloseReject}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="icon-box justify-content-center">
              <i className="icon-confirm">
                <FontAwesomeIcon icon={faClose} />
              </i>
            </div>
            <div className="text-center mt-1" >
              <h5 style={{fontSize: '100%', color: 'gray'}}>Do you really want to reject these leave requests?</h5>
            </div>
            <div className="mt-4">
              <label htmlFor="reason" className="form-label d-block fw-semibold">Reason for Disapproval</label>
              <textarea
                  id="reason"
                  className="form-control"
                  rows={2}
                  placeholder="Enter your reason here"
                  style={{ resize: 'none', fontSize: '95%' }}
                  value={reasonRejection}
                  onChange={(e: any) => setReasonRejection(e.target.value)}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant='outline-primary' className='mx-2' size='sm' onClick={handleCloseReject}>
              Cancel
            </Button>
            <Button
                variant='primary text-white'
                size='sm'
                className='mx-2'
                // disabled={reasonRejection == "" ? true : false}
                onClick={() => rejectLeaveRequest(item.leave_application_id)}
            >
              Disapprove
            </Button>
          </Modal.Footer>
        </Modal>



        <Loader show={showLoading}/>

        {/* DETAIL MODAL*/}
        <Modal className='modal' centered size={'lg'} show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>
              <Stack direction='horizontal'>
                <div>
                  {
                    item.employee.photo != "" ?
                        <Avatar
                            sx={{ width: 65, height: 65 }}
                            src={process.env.REACT_APP_IMAGE_URL+ item.employee.photo}
                        ></Avatar> :
                        <Avatar
                            sx={{ width: 65, height: 65}}
                            src={""}
                        ></Avatar>
                  }
                </div>
                <div className='mx-3'>
                  &nbsp;{item.employee?.first_name} {item.employee?.last_name}
                  <br />
                  &nbsp;<span className='text-muted fw-light'>{item.employee?.user_name?.role?.role_name}</span>
                </div>
              </Stack>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      Leave Type:
                    </td>
                    <td>{item.leave_type?.leave_type_name}</td>
                  </tr>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      No. of Days:
                    </td>
                    <td >{handleDisplayDays(item.number_of_day)}</td>
                  </tr>

                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      Applied By:
                    </td>
                    <td>{item.created_by_first_name} {item.created_by_last_name}</td>
                  </tr>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      Applied On:
                    </td>
                    <td>{item.application_date}</td>
                  </tr>
                  {/*{*/}
                  {/*  item.status == 2 ?*/}
                  {/*      <tr className='lh-1'>*/}
                  {/*        <td className='fw-bold' width={120}>*/}
                  {/*          Rejected By:*/}
                  {/*        </td>*/}
                  {/*        <td>{item.rejected_by_first_name} {item.rejected_by_last_name}</td>*/}
                  {/*      </tr> : ""*/}
                  {/*}*/}
                  </tbody>
                </Table>
              </Col>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      Date of Leave:
                    </td>
                    <td>{item.application_from_date} - {item.application_to_date}</td>
                  </tr>
                    <tr className='lh-1'>
                      <td className='fw-bold' width={120}>
                        Reason:
                      </td>
                      <td>{item.purpose}</td>
                    </tr>
                    {
                      item.status == 2 ?
                          <tr className='lh-1'>
                            <td className='fw-bold' width={120}>
                              Reason for Disapproval:
                            </td>
                            <td>{item.remarks}</td>
                          </tr> : ""
                    }

                  </tbody>
                </Table>
              </Col>

            </Row>
            <hr/>
            <Row style={{marginTop: '-15px'}}>
              <Table borderless className='mt-0 mb-0'>
                <tbody>
                <tr className='lh-1'>
                  <th className='text-center'>Approvals</th>
                </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col sm={12}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <th className='text-center' style={{width: '20%'}}>Levels</th>
                    <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                    <th className='text-center'>Role</th>
                    <th className='text-center' style={{width: '15%'}}>Status</th>
                    <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                  </tr>
                  {
                    item.request_status.length > 0 ? (
                        item.request_status.map((item1: any, index: any) =>
                            (
                                <tr key={index} className='lh-1'>
                                  <td>
                                    {
                                      index != 0 && item.request_status[index - 1].level == item1.level ?
                                          "" : <>
                                            <b>Level {item1.level}</b> : {item1.approvers_order == "0" ? "By Order" : "Anyone"}
                                          </>
                                    }
                                  </td>
                                  <td>
                                    <Stack direction='horizontal'>
                                      <div>
                                        {
                                          item1.employee?.photo != "" ?
                                              <Avatar
                                                  sx={{ width: 20, height: 20 }}
                                                  src={process.env.REACT_APP_IMAGE_URL+ item1.employee?.photo}
                                                  className={"profile-avatar"}
                                              ></Avatar> :
                                              <Avatar
                                                  sx={{ width: 20, height: 20}}
                                                  src={""}
                                                  className={"profile-avatar"}
                                              ></Avatar>
                                        }
                                      </div>
                                      <div style={{color: item1.current == "1" ? "#ff7043": ""}}>&nbsp;{item1.employee?.first_name} {item1.employee?.last_name}</div>
                                    </Stack>
                                  </td>
                                  <td>{item1.employee?.user_name?.role?.role_name}</td>
                                  {(() => {
                                    // Approved:2
                                    if (item1.status == '3') {

                                      return (
                                          <>
                                            <td className='fw-bold text-center'>
                                              <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                                Approved
                                              </Button>
                                            </td>
                                            <td className='text-center'>{moment(new Date(item1.approve_date ?? "")).format(dateFormat)}</td>
                                          </>
                                      )

                                      // Reject:3
                                    } else if(item1.status == '2') {
                                      return (
                                          <>
                                            <td className='fw-bold text-center'>
                                              <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                                Disapproved
                                              </Button>
                                            </td>
                                            <td className='text-center'>{moment(new Date(item1.reject_date ?? "")).format(dateFormat)}</td>

                                          </>
                                      )
                                    } else if(item1.status == '4') {
                                      return (
                                          <>
                                            <td className='text-center'>-</td>
                                            <td className='text-center'>-</td>
                                          </>

                                      )
                                    }

                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                              Pending
                                            </Button>
                                          </td>
                                          <td className='text-center'>-</td>
                                        </>
                                    )
                                  })()}


                                </tr>

                            )

                        )
                    ) : (
                        <></>
                    )
                  }
                  </tbody>
                </Table>
              </Col>

            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              // onClick={() => rejectLeaveRequest(item.leave_application_id)}
                onClick={() => handleShowReject(item.leave_application_id)}
              variant='outline-primary' 
              className=' px-5 pe-5 mx-2'  
              size={'sm'}
              disabled={handleDisableAcceptRejectButton(item)}
              >
              Disapprove
            </Button>
            <Button 
              // onClick={() => acceptLeaveRequest(item.leave_application_id)}
                onClick={() => handleShowAccept(item.leave_application_id)}
              variant='primary' 
              className='text-white px-5 pe-5' 
              size={'sm'}
              disabled={handleDisableAcceptRejectButton(item)}
              >
              Approve
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ADD MODAL*/}
        <Formik
            initialValues={{
              employee_id: 0,
              leave_type_id: '',
              application_from_date: '',
              application_to_date: '',
              number_of_day: '',
              purpose: '',
              isHalfOrWholeDay: ''
            }}
            validationSchema={validationSchema}
            onSubmit={postLeaveApplication}
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form id="leaveApplicationCreate" noValidate onSubmit={handleSubmit}>
                <Modal className='modal' centered show={showCreate} onHide={handleCloseCreate}>
                  <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>Leave Application Form</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold form-labels required'>Employees</Form.Label>
                              <Select
                                  name="employee_id"
                                  value={selectedEmployeeOption}
                                  options={employeeOptions}
                                  onChange={(e:any )=> {
                                    handleChangeDropdownEmployee(e)
                                    values.employee_id = e.value
                                  }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.employee_id}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold'>Leave Type</Form.Label>
                              <Form.Select
                                  size={'sm'}
                                  name="leave_type_id"
                                  value={values.leave_type_id}
                                  onChange={e => {
                                    handleChange(e)
                                    showLeaveBalance(parseInt(e.target.value))
                                  }}
                                  onBlur={handleBlur}
                                  isInvalid={touched.leave_type_id && !!errors.leave_type_id}
                              >
                                <option value="">Select Leave Type</option>
                                {leaveTypes.map((leaveType, index) => (
                                    <option value={leaveType.leave_type_id} key={index}>{leaveType.leave_type_name}</option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback className="left" type="invalid">
                                {errors.leave_type_id}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold'>No. of Days </Form.Label>
                              <Form.Control
                                  size='sm'
                                  type='number'
                                  step={0.5}
                                  placeholder=''
                                  name="number_of_day"
                                  value={values.number_of_day}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={touched.number_of_day && !!errors.number_of_day}
                                  disabled={true}
                              />
                              <Form.Control.Feedback className="left" type="invalid">
                                {errors.number_of_day}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold'>Balance </Form.Label>
                              <Form.Control
                                  size='sm'
                                  type='number'
                                  step={0.5}
                                  placeholder=''
                                  value={leaveCredit.credits}
                                  disabled={true}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold'>Date From </Form.Label>
                              <InputGroup size={'sm'}>
                                <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                  <Calendar/>
                                </InputGroup.Text>
                                <Form.Control
                                    size='sm'
                                    type='date'
                                    placeholder=''
                                    name="application_from_date"
                                    value={values.application_from_date}
                                    onChange={e => {
                                      handleChange(e)
                                      if (e.target.value != '' && values.application_to_date != '') {
                                        const days = getNumberOfDays(
                                            e.target.value,
                                            values.application_to_date
                                        );
                                        values.number_of_day = days.toString();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={touched.application_from_date && !!errors.application_from_date}
                                />
                                <Form.Control.Feedback className="left" type="invalid">
                                  {errors.application_from_date}
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold'>Date To </Form.Label>
                              <InputGroup size={'sm'}>
                                <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                  <Calendar/>
                                </InputGroup.Text>
                                <Form.Control
                                    size='sm'
                                    type='date'
                                    placeholder=''
                                    name="application_to_date"
                                    value={values.application_to_date}
                                    onChange={e => {
                                      handleChange(e)
                                      if (e.target.value != '' && values.application_from_date != '') {
                                        const days = getNumberOfDays(
                                            values.application_from_date,
                                            e.target.value
                                        );
                                        values.number_of_day = days.toString();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={touched.application_to_date && !!errors.application_to_date}
                                />
                                <Form.Control.Feedback className="left" type="invalid">
                                  {errors.application_to_date}
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                        <Form.Label className='fw-bold'>Reason</Form.Label>
                        <Form.Control
                            as='textarea'
                            rows={3}
                            name="purpose"
                            value={values.purpose}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.purpose && !!errors.purpose}
                        />
                        <Form.Control.Feedback className="left" type="invalid">
                          {errors.application_to_date}
                        </Form.Control.Feedback>
                      </Form.Group>

                      {['radio'].map((type: any) => (
                          <div key={`inline-${type}`} className='mb-3'>
                            <Form.Check
                                inline
                                label='Whole Day'
                                name='isHalfOrWholeDay'
                                type={type}
                                value='whole_day'
                                id={`inline-${type}-1`}
                                onChange={e => {
                                  handleChange(e)
                                  setShowHourly(true)
                                  if (values.application_from_date  != '' && values.application_to_date != '') {
                                    const days = getNumberOfDays(
                                        values.application_from_date,
                                        values.application_to_date
                                    );
                                    values.number_of_day = days.toString();
                                  }
                                }}
                                checked={values.isHalfOrWholeDay === 'whole_day'}
                                isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                            />
                            <Form.Check
                                inline
                                label='First Half'
                                name='isHalfOrWholeDay'
                                type={type}
                                value='first_half'
                                id={`inline-${type}-2`}
                                onChange={e => {
                                  handleChange(e)
                                  setShowHourly(true)
                                  values.number_of_day = '0.5';
                                }}
                                checked={values.isHalfOrWholeDay === 'first_half'}
                                isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                            />
                            <Form.Check
                                inline
                                name='isHalfOrWholeDay'
                                label='Second Half'
                                type={type}
                                value='second_half'
                                id={`inline-${type}-3`}
                                onChange={e => {
                                  handleChange(e)
                                  setShowHourly(true)
                                  values.number_of_day = '0.5';
                                }}
                                checked={values.isHalfOrWholeDay === 'second_half'}
                                isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                            />

                            <span style={{ color: '#dc3545', fontSize: '0.875em' }}>
                          {touched.isHalfOrWholeDay && errors.isHalfOrWholeDay}
                        </span>
                          </div>
                      ))}
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                      Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        form="leaveApplicationCreate"
                        variant='primary text-white'
                        size={'sm'}
                        className='mx-2'
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
          )}
        </Formik>

        <Dialog
            show={showConfirm}
            setShow={handleCloseConfirm}
            setEvent={() => {
              deleteLeaveApplication(item.leave_application_id)
            }
            }
        />
        <br />
      </Container>
    </>
  )
}
