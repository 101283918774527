import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Formik } from 'formik';
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { validationSchema } from './validationSchema'
import {IDeduction, IList, IItem, IDeMinimisList, IDeMinimis} from './types'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

export default function Index() {
  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [currentPage2, setCurrentPage2] = React.useState(0)
  const [lastPage2, setLastPage2] = React.useState(0)
  const [itemsPerPage2, setPerPage2] = React.useState(10)
  const [itemsCount2, setItemCount2] = React.useState(0)
  const [from2, setFrom2] = React.useState(0)

  const [validation, setValidation] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)

  const [key, setKey] = React.useState("allowances")

  const [showCreate, setShowCreate] = React.useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)

  const [showEdit, setShowEdit] = React.useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = React.useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      allowance_id: id,
      allowance_name: '',
      allowance_type: '',
      percentage_of_basic: '',
      limit_per_month: '',
      when_to_add: '',
      de_minimis_id: 0
    })
  }
  const [amountLabel, setAmountLabel] = React.useState("Limit per Month")
  const [amountLabelEdit, setAmountLabelEdit] = React.useState("Limit per Month")

  const [lists, setData] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    allowance_id: 0,
    allowance_name: '',
    allowance_type: '',
    percentage_of_basic: '',
    limit_per_month: '',
    when_to_add: '',
    de_minimis_id: 0
  })
  const [deMinimis, setDeMinimis] = useState<IDeMinimisList[]>([])
  const [deMinimisList, setDeMinimisList] = useState([{
    'id': 0,
    'name': "",
    'limit_per_month': "",
    'limit_per_year': "",
    'salary_percentage': ""
  }])

  const handleChangeAllowanceTypeEdit = (e:any) => {

    if(e.target.value == "Percentage"){
      setAmountLabelEdit("Percentage of Basic")
    }else{
      setAmountLabelEdit("Limit per Month")
    }

  }

  const handleChangeAllowanceTypeAdd = (e:any) => {

    if(e.target.value == "Percentage"){
      setAmountLabel("Percentage of Basic")
    }else{
      setAmountLabel("Limit per Month")
    }

  }

  const getAllowance = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IDeduction>>(API_ENDPOINT.GET_ALLOWANCE, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showAllowance = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_ALLOWANCE +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postAllowance = async (value: any, {resetForm} : any) => {
    await axios.post(API_ENDPOINT.POST_ALLOWANCE, {
      allowance_name: value.allowance_name,
      allowance_type: value.allowance_type,
      limit_per_month: value.limit_per_month,
      when_to_add: value.when_to_add,
      de_minimis_id: value.de_minimis_id
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.ALLOWANCE.CREATED)
          getAllowance(null);
       }
       resetForm({})
       handleCloseCreate();
    }).catch(error => {
      setValidation(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const updateAllowance = async (value: any, {resetForm} : any) => {
    let updateParams = {};
    if(value.allowance_type == "Percentage"){
      updateParams = {
        allowance_name: value.allowance_name,
        allowance_type: value.allowance_type,
        percentage_of_basic: value.limit_per_month,
        limit_per_month: 0,
        when_to_add: value.when_to_add,
        de_minimis_id: value.de_minimis_id
      }
    }else{
      updateParams = {
        allowance_name: value.allowance_name,
        allowance_type: value.allowance_type,
        percentage_of_basic: 0,
        limit_per_month: value.limit_per_month,
        when_to_add: value.when_to_add,
        de_minimis_id: value.de_minimis_id
      }
    }

    await axios.put(API_ENDPOINT.PUT_ALLOWANCE +'/'+ value.allowance_id, updateParams).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.ALLOWANCE.UPDATED)
          getAllowance(null);
       }
       resetForm({})
       handleCloseEdit();
    }).catch(error => {
      setValidation(error.response.data.message)
    })
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const deleteAllowance = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_ALLOWANCE +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.DEDUCTION.DELETED)
        getAllowance(null);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getDeMinimis = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IDeMinimis>>(API_ENDPOINT.GET_DEMINIMIS, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setDeMinimis(result?.data?.results?.data)
        setCurrentPage2(result?.data?.results?.current_page)
        setLastPage2(result?.data?.results?.last_page)
        setPerPage2(result?.data?.results?.per_page)
        setItemCount2(result?.data?.results?.total)
        setFrom2(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getAllDeMinimis = async () => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IDeMinimisList[]>>(API_ENDPOINT.GET_ALL_DEMINIMIS)
      const result = await response
      if (result?.data?.results?.length) {
        setDeMinimisList(result?.data?.results)
      }else{
        setDeMinimis([])
      }


    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getAllowance(null)
      getDeMinimis(null)
      getAllDeMinimis()
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Allowance</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='#'>
            Setup
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='#'>
            Payroll
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Allowance</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />
        <Tabs
            variant={'tabs'}
            className='tabs '
            id='controlled-tab-example'
            activeKey={key}
            onSelect={(k: any) => {
              setKey(k)
            }}
        >
          <Tab eventKey='allowances' title='Allowances'>
            <Card className='border-0  p-0 bordered-card'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>&nbsp;</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25'}>
                      <Form.Control
                          aria-label='search'
                          aria-describedby='inputGroup-sizing-sm'
                          placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                    <Button
                        onClick={handleShowCreate}
                        className=' add-new-btn rounded mx-2 text-white'
                        variant='warning'
                    >
                      <EditIcon/>
                      &nbsp;Add Allowance
                    </Button>{' '}
                  </Col>
                </Row>

                <Table responsive className='bordered-tabs-top mt-3'>
                  <thead>
                  <tr>
                    <th style={{ width: '5%' }} className='text-center'>
                      No.
                    </th>
                    <th style={{ width: '20%' }} className='text-center'>
                      Allowance Name
                    </th>
                    <th style={{ width: '20%' }} className='text-center'>
                      Allowance Type
                    </th>
                    <th style={{ width: '20%' }} className='text-center'>
                      De Minimis
                    </th>
                    <th style={{ width: '20%' }} className='text-center'>
                      When to Add
                    </th>
                    <th className={'text-center'}>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <>
                    {lists.length > 0 ? (
                        lists.map((item, index) =>
                            <tr className='mb-2' key={index}>
                              <td>{from + index}</td>
                              <td>{item.allowance_name}</td>
                              <td className="text-center">{item.allowance_type}</td>
                              {/*<td className='text-right'>{item.percentage_of_basic}%</td>*/}
                              {/*<td className='text-right'>{item.limit_per_month}</td>*/}
                              <td>{item.de_minimis == null ? "Not included": item.de_minimis.name}</td>
                              <td className='text-center'>{item.when_to_add == "first_payroll" ? "First Payroll" :
                                  item.when_to_add == "second_payroll" ? "Second Payroll" :
                                      item.when_to_add == "divide_equally" ? "Divide Equally" : "" }</td>
                              <td className='text-center'>
                                <Button
                                    onClick={() => showAllowance(item.allowance_id)}
                                    className=' p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                >
                                  <Write/>
                                </Button>
                                <Button
                                    onClick={() => handleShowConfirm(item.allowance_id)}
                                    className='p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px' }}
                                    variant='transparent'
                                    size='sm'
                                >
                                  <Delete/>
                                </Button>
                              </td>
                            </tr>
                        )
                    ) : (
                        <tr className='no-item'>
                          <td colSpan={6}>{TEXT.NOT_FOUND}</td>
                        </tr>
                    )}
                  </>
                  </tbody>
                </Table>

                <Pagination
                    itemsCount={itemsCount}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getAllowance(page);
                    }
                    }
                />
              </Card.Body>
            </Card>
          </Tab>
          <Tab eventKey='de_minimis' title='De Minimis'>
            <Card className='border-0  p-0 bordered-card'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>&nbsp;</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25'}>
                      <Form.Control
                          aria-label='search'
                          aria-describedby='inputGroup-sizing-sm'
                          placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Row>

                <Table responsive className='bordered-tabs-top mt-3'>
                  <thead>
                  <tr>
                    <th style={{ width: '10%' }} className='text-center'>
                      No.
                    </th>
                    <th style={{ width: '45%' }} className='text-center'>
                      Name
                    </th>
                    <th style={{ width: '45%' }} className='text-center'>
                      Limit
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <>
                    {deMinimis.length > 0 ? (
                        deMinimis.map((item, index) =>
                            <tr className='mb-2' key={index}>
                              <td>{from2 + index}</td>
                              <td>{item.name}</td>
                              <td className="text-center">{item.limit_per_month != "0.00" ?
                                  Number(item.limit_per_month).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " per month" : item.limit_per_year != "0.00" ?
                                      Number(item.limit_per_year).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " per year" : item.salary_percentage != "0.00" ?
                                          Number(item.salary_percentage).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "% of the salary" : ""}</td>
                            </tr>
                        )
                    ) : (
                        <tr className='no-item'>
                          <td colSpan={3}>{TEXT.NOT_FOUND}</td>
                        </tr>
                    )}
                  </>
                  </tbody>
                </Table>

                <Pagination
                    itemsCount={itemsCount2}
                    itemsPerPage={itemsPerPage2}
                    currentPage={currentPage2}
                    lastPage={lastPage2}
                    setCurrentPage={setCurrentPage2}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getDeMinimis(page);
                    }
                    }
                />
              </Card.Body>
            </Card>
          </Tab>
        </Tabs>
        <Dialog
            show={showConfirm}
            setShow={handleCloseConfirm}
            setEvent={() => {
              deleteAllowance(item.allowance_id)
            }
            }
        />

        <Loader show={showLoading}/>

        {/* ADD MODAL*/}
        <Formik
          initialValues={{ 
            allowance_name: '',
            allowance_type: '',
            limit_per_month: '',
            when_to_add: '',
            de_minimis_id: 0
          }}
          validationSchema={validationSchema}
          onSubmit={postAllowance}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

          <Form id="allowanceCreate" noValidate onSubmit={handleSubmit}>
            <Modal className='modal' centered show={showCreate} onHide={handleCloseCreate}>
              <Modal.Header closeButton>
                <Modal.Title className='fw-bold'>Add New Allowance</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='fw-bold required'>Allowance Name</Form.Label>
                        <Form.Control 
                          size={'sm'} 
                          type='text' 
                          placeholder='Allowance Name' 
                          name="allowance_name"
                          value={values.allowance_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.allowance_name && !!errors.allowance_name}
                          />
                          {
                          validation != '' ?
                            (Object.keys(validation).map((message : any, index) => (
                              <Form.Control.Feedback type="invalid" key={index}>
                                {validation[message][index]}
                              </Form.Control.Feedback>
                            ))
                            ) : (
                            <Form.Control.Feedback type="invalid">
                              {errors.allowance_name}
                            </Form.Control.Feedback>
                            )
                          }
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='fw-bold required'>Allowance Type</Form.Label>
                        <Form.Select 
                          size={'sm'}
                          name="allowance_type"
                          value={values.allowance_type}
                          onBlur={handleBlur}
                          onChange={ event => {
                            handleChange(event);
                            handleChangeAllowanceTypeAdd(event)
                          }

                          }
                          isInvalid={touched.allowance_type && !!errors.allowance_type}
                          >
                          <option value='' selected>Select Allowance Type</option>
                          <option value='Percentage'>Percentage</option>
                          <option value='Fixed'>Fixed</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.allowance_type}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='fw-bold required'>{amountLabel}</Form.Label>
                        <Form.Control
                          className="text-right"
                          min={0}
                          step={0.01}
                          size={'sm'}
                          type='number'
                          placeholder='0'
                          name="limit_per_month"
                          value={values.limit_per_month}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.limit_per_month && !!errors.limit_per_month}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.limit_per_month}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='fw-bold required'>Allowance Schedule</Form.Label>
                        <Form.Select
                            size={'sm'}
                            name="when_to_add"
                            value={values.when_to_add}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.when_to_add && !!errors.when_to_add}
                        >
                          <option value='' selected>Select When</option>
                          <option value='first_payroll'>First Payroll</option>
                          <option value='second_payroll'>Second Payroll</option>
                          <option value='divide_equally'>Divide Equally</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.when_to_add}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='fw-bold required'>De Minimis</Form.Label>
                        <Form.Select
                            size={'sm'}
                            name="de_minimis_id"
                            value={values.de_minimis_id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.de_minimis_id && !!errors.de_minimis_id}
                        >
                          <option value='0' selected>Not included</option>
                          {
                            deMinimisList.length > 0 ?
                                deMinimisList.map((deminimis, index) => (
                                    <option key={index} value={deminimis.id}>{deminimis.name}</option>
                                )) :
                                ""
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.de_minimis_id}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                  Cancel
                </Button>
                <Button 
                  type="submit"
                  disabled={isSubmitting}
                  form="allowanceCreate" 
                  variant='primary text-white'
                  size={'sm'}
                  className='mx-2'
                  >
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
          initialValues={item}
          validationSchema={validationSchema}
          onSubmit={updateAllowance}
          enableReinitialize
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

          <Form id="allowanceEdit" noValidate onSubmit={handleSubmit}>
            <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
              <Modal.Header closeButton>
                <Modal.Title className='fw-bold'>Edit Allowance</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='fw-bold required'>Allowance Name</Form.Label>
                        <Form.Control 
                          size={'sm'} 
                          type='text' 
                          placeholder='Allowance Name' 
                          name="allowance_name"
                          value={values.allowance_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.allowance_name && !!errors.allowance_name}
                          />
                          {
                          validation != '' ?
                            (Object.keys(validation).map((message : any, index) => (
                              <Form.Control.Feedback type="invalid" key={index}>
                                {validation[message][index]}
                              </Form.Control.Feedback>
                            ))
                            ) : (
                            <Form.Control.Feedback type="invalid">
                              {errors.allowance_name}
                            </Form.Control.Feedback>
                            )
                          }
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='fw-bold required'>Allowance Type</Form.Label>
                        <Form.Select 
                          size={'sm'}
                          name="allowance_type"
                          value={values.allowance_type}
                          onBlur={handleBlur}
                          onChange={event => {
                            handleChange(event);
                            handleChangeAllowanceTypeEdit(event)
                            }
                          }
                          isInvalid={touched.allowance_type && !!errors.allowance_type}
                          >
                          <option value='' selected>Select Allowance Type</option>
                          <option value='Percentage'>Percentage</option>
                          <option value='Fixed'>Fixed</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.allowance_type}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='fw-bold required'>{amountLabelEdit}</Form.Label>
                        <Form.Control
                          min={0}
                          step={0.01}
                          size={'sm'}
                          type='number'
                          placeholder='Limit Per Month'
                          name="limit_per_month"
                          value={values.limit_per_month}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.limit_per_month && !!errors.limit_per_month}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.limit_per_month}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='fw-bold required'>Allowance Schedule</Form.Label>
                        <Form.Select
                            size={'sm'}
                            name="when_to_add"
                            value={values.when_to_add}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.when_to_add && !!errors.when_to_add}
                        >
                          <option value='' selected>Select When</option>
                          <option value='first_payroll'>First Payroll</option>
                          <option value='second_payroll'>Second Payroll</option>
                          <option value='divide_equally'>Divide Equally</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.when_to_add}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='fw-bold required'>De Minimis</Form.Label>
                        <Form.Select
                            size={'sm'}
                            name="de_minimis_id"
                            value={values.de_minimis_id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.de_minimis_id && !!errors.de_minimis_id}
                        >
                          <option value='0' selected>Not included</option>
                          {
                            deMinimisList.length > 0 ?
                                deMinimisList.map((deminimis, index) => (
                                    <option key={index} value={deminimis.id}>{deminimis.name}</option>
                                )) :
                                ""
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.de_minimis_id}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                  Cancel
                </Button>
                <Button 
                  type="submit"
                  disabled={isSubmitting}
                  form="allowanceEdit" 
                  variant='primary text-white'
                  size={'sm'}
                  className='mx-2'
                  >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
          )}
        </Formik>
        <br />
      </Container>
    </>
  )
}
