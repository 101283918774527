import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Stack,
  Table,
} from 'react-bootstrap'
import './style.css'
import Delete from 'asset/SvgComponent/Delete'
import Write from 'asset/SvgComponent/Write'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Calendar from 'asset/SvgComponent/Calendar'
import Clock from 'asset/SvgComponent/Clock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Avatar } from '@mui/material'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import { Formik } from 'formik';
import { validationSchema } from './validationSchema'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { ILeaveApplication, ILeaveType, ILeaveBalance, IList, IItem, ILeaveCredit } from './types'
import moment from "moment"
import Select from "components/ReactSelect";
import {IEmployee} from "../../../Attendance/AttendanceReport/Construction/types";
import View from "asset/SvgComponent/View";
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import ReactToPrint from "react-to-print";

export default function Index() {
  const [key, setKey] = useState('pending')
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [currentPage1, setCurrentPage1] = useState(0)
  const [lastPage1, setLastPage1] = useState(0)
  const [itemsPerPage1, setPerPage1] = useState(10)
  const [itemsCount1, setItemCount1] = useState(0)
  const [from1, setFrom1] = useState(0)

  const [showCreate, setShowCreate] = useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)

  const [showEdit, setShowEdit] = useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      leave_application_id: id,
      employee_id: 0,
      application_from_date: '',
      application_to_date: '',
      application_date: '',
      number_of_day: '',
      approve_date: '',
      reject_date: '',
      approve_by: '',
      reject_by: '',
      purpose: '',
      remarks: '',
      status: '',
      isHalfOrWholeDay: '',
      hour_from: '',
      hour_to: '',
      leave_type_id: '',
      leave_type: {
        leave_type_id: 0,
        leave_type_name: '',
        leave_description: ''
      },
      employee: {
        first_name: "",
        last_name: "",
        employee_id: 0,
        user_name: {
          role: {
            role_name: ""
          }
        },
        photo: ""
      },
      credits: {
        id: 0,
        employee_id: 0,
        leave_type: 0,
        credits: "",
        is_unlimited: 0
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: "",
        approvers_order: ""
      }],
      approvers_order: 0,
      created_by_first_name: "",
      created_by_last_name: "",
      rejected_by_first_name: "",
      rejected_by_last_name: ""
    })
  }

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showHourly, setShowHourly] = useState(true)

  const [lists, setData] = useState<IList[]>([])
  const [listsCompleted, setDataCompleted] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    leave_application_id: 0,
    employee_id: 0,
    application_from_date: '',
    application_to_date: '',
    application_date: '',
    number_of_day: '',
    approve_date: '',
    reject_date: '',
    approve_by: '',
    reject_by: '',
    purpose: '',
    remarks: '',
    status: '',
    isHalfOrWholeDay: '',
    leave_type_id: '',
    hour_from: '',
    hour_to: '',
    leave_type: {
      leave_type_id: 0,
      leave_type_name: '',
      leave_description: ''
    },
    employee: {
      first_name: "",
      last_name: "",
      employee_id: 0,
      user_name: {
        role: {
          role_name: ""
        }
      },
      photo: ""
    },
    credits: {
      id: 0,
      employee_id: 0,
      leave_type: 0,
      credits: "",
      is_unlimited: 0
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      level: "",
      approvers_order: ""
    }],
    approvers_order: 0,
    created_by_first_name: "",
    created_by_last_name: "",
    rejected_by_first_name: "",
    rejected_by_last_name: ""
  })
  const [leaveTypes, setLeaveTypes] = useState<ILeaveType[]>([
    { 
      leave_type_id: 0,
      leave_type_name: '',
      leave_description: ''
    }
  ])
  const [leaveBalance, setLeaveBalance] = useState<ILeaveBalance>(
    { 
      balance: 0,
      num_of_day: 0,
    }
  )
  const [leaveCredit, setLeaveCredit] = useState<ILeaveCredit>(
      {
        id: 0,
        employee_id: 0,
        leave_type: 0,
        credits: "",
        is_unlimited: 0
      }
  )
  const [leaveCreditOnEdit, setLeaveCreditOnEdit] = useState<ILeaveCredit>(
      {
        id: 0,
        employee_id: 0,
        leave_type: 0,
        credits: "",
        is_unlimited: 0
      }
  )
  const [dateFormat, setDateFormat] = useState('')
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
  const [selectedEmployeeOptionEdit, setSelectedEmployeeOptionEdit] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [searched, setSearched] = useState("")
  const [searchedCompleted, setSearchedCompleted] = useState("")
  const [dateFilterPending, setDateFilterPending] = useState({dateFrom: '', dateTo: ''})
  const [dateFilterCompleted, setDateFilterCompleted] = useState({dateFrom: '', dateTo: ''})
  const [status, setStatus] = useState(0)

  const handleDisplayDays = (day: string|null) => {
    const dayFormat = parseFloat(day ?? "0")
    if(dayFormat > 1){
      return `${dayFormat} days`
    }else{
      return `${dayFormat} day`
    }
  }

  const handleChangeDropdownEmployee = (selectedOption: any) => {
    setSelectedEmployeeOption(selectedOption)
  }

  const handleChangeDropdownEmployeeEdit = (selectedOption: any) => {
    setSelectedEmployeeOptionEdit(selectedOption)
  }

  const handleChangeSearchedKeyword = (e:any) => {
    setSearched(e.target.value)

    getLeaveApplication(null, e.target.value, status, dateFilterPending.dateFrom, dateFilterPending.dateTo)
  }

  const handleChangeSearchedKeywordForCompleted = (e:any) => {
    setSearchedCompleted(e.target.value)

    getLeaveApplication(null, e.target.value, 1, dateFilterCompleted.dateFrom, dateFilterCompleted.dateTo)
  }

  const showLeaveRequest = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_LEAVE_REQUEST_FOR_MFBC +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
      }
      handleShow()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getAllEmployee = async () => {
    try {
      const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
      const result = await res
      if (result?.data?.results?.length) {
        const employees = result?.data?.results;
        if(JSON.parse(localStorage.getItem('role') ?? "") != "Admin" &&
            JSON.parse(localStorage.getItem('role') ?? "") != "Supervisor"){
          employees.map(employee => {
            if(employee.employee_id == Number(localStorage.getItem('employee_id'))){
              setSelectedEmployeeOption({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
              // setDisableSelect(true)
              setEmployeeOptions([])
              setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
              setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
            }
          })


        }else{
          setEmployeeOptions([])
          setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
          employees.map(employee => {
            setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
          })
        }

      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getNumberOfDays = (startDate: any, endDate: any) => {
     //define two date object variables with dates inside it  
    const date1 = new Date(startDate);  
    const date2 = new Date(endDate);  

    //calculate time difference  
    const time_difference = date2.getTime() - date1.getTime();  

    //calculate days difference by dividing total milliseconds in a day  
    return (time_difference / (1000 * 60 * 60 * 24)) + 1;  
  };

  const showLeaveBalance = async (id: null | number) => {
    const res = await axios.get<IAPI_Response<ILeaveCredit>>(API_ENDPOINT.SHOW_LEAVE_CREDITS +'/'+ id+'/'+selectedEmployeeOption.value)
    const result = await res
    if (result?.data?.results) {
      setLeaveCredit(result?.data?.results)
    }
  }

  const showLeaveBalanceOnEdit = async (id: null | number) => {
    const res = await axios.get<IAPI_Response<ILeaveCredit>>(API_ENDPOINT.SHOW_LEAVE_CREDITS +'/'+ id+'/'+selectedEmployeeOptionEdit.value)
    const result = await res
    if (result?.data?.results) {
      setLeaveCreditOnEdit(result?.data?.results)
    }
  }

  const filterLeaveApplicationPending = async (values : any) => {
    setDateFilterPending({dateFrom: values.date_from, dateTo: values.date_to})
    getLeaveApplication(currentPage, searched, values.status, values.date_from, values.date_to)
  }

  const filterLeaveApplicationCompleted = async (values : any) => {
    setDateFilterCompleted({dateFrom: values.date_from, dateTo: values.date_to})
    getLeaveApplication(currentPage1, searchedCompleted, 1, values.date_from, values.date_to)
  }

  const getLeaveApplication = async (page_number: null | number, searchedKeyword: string, status: number, dateFrom: string, dateTo: string) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<ILeaveApplication>>(API_ENDPOINT.GET_LEAVE_APPLICATION, {
        params: {
          page: page_number,
          searched: searchedKeyword,
          status: status,
          date_from: dateFrom,
          date_to: dateTo
        },
      })
      const result = await response
      // if(status == 0) {
        if (result?.data?.results?.data?.length) {
          setData(result?.data?.results?.data)
          setCurrentPage(result?.data?.results?.current_page)
          setLastPage(result?.data?.results?.last_page)
          setPerPage(result?.data?.results?.per_page)
          setItemCount(result?.data?.results?.total)
          setFrom(result?.data?.results?.from)
        }else{
          setData([])
        }
      // }else{
      //   if (result?.data?.results?.data?.length) {
      //     setDataCompleted(result?.data?.results?.data)
      //     setCurrentPage1(result?.data?.results?.current_page)
      //     setLastPage1(result?.data?.results?.last_page)
      //     setPerPage1(result?.data?.results?.per_page)
      //     setItemCount1(result?.data?.results?.total)
      //     setFrom1(result?.data?.results?.from)
      //   }else{
      //     setDataCompleted([])
      //   }
      // }

      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getLeaveType = async () => {
    const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_LEAVE_CONFIGURATION)
    const result = await res
    if (result?.data?.results?.length) {
      setLeaveTypes(result?.data?.results)
    }
  }

  const showLeaveApplication = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_LEAVE_APPLICATION +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
        setSelectedEmployeeOptionEdit({label: result?.data?.results?.employee.first_name + " " + result?.data?.results?.employee.last_name, value: result?.data?.results?.employee_id})
        setLeaveCreditOnEdit(result?.data?.results?.credits)
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postLeaveApplication = async (value: any, {resetForm} : any) => {

    if(leaveCredit.is_unlimited == 1 || (leaveCredit.is_unlimited == 0 && leaveCredit.credits && parseFloat(leaveCredit.credits) > 0)){

      if(value.employee_id != 0){
        await axios.post(API_ENDPOINT.POST_LEAVE_APPLICATION_FOR_MFBC, {
          leave_type_id: value.leave_type_id,
          application_from_date: value.application_from_date,
          application_to_date: value.application_to_date,
          number_of_day: value.number_of_day,
          purpose: value.purpose,
          isHalfOrWholeDay: value.isHalfOrWholeDay,
          employee_id: value.employee_id
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.LEAVE_APPLICATION.CREATED)
            getLeaveApplication(null, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo);
          }
          resetForm({})
          handleCloseCreate();
        }).catch((err) => {
          setShowError(true)
          if(err.response.data.message == "application_exist"){
            setError(ERROR_MESSAGE.leave_application_exist)
          }else{
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
          }
        });
      }else{
        setError("Please select employee.")
        setShowError(true)
      }

    }else{
      setError("You have insufficient leave credit balance.")
      setShowError(true)
    }


    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const updateLeaveApplication = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_LEAVE_APPLICATION +'/'+ value.leave_application_id, {
      employee_id: value.employee_id,
      leave_type_id: value.leave_type_id,
      application_from_date: value.application_from_date,
      application_to_date: value.application_to_date,
      number_of_day: value.number_of_day,
      purpose: value.purpose,
      isHalfOrWholeDay: value.isHalfOrWholeDay
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.LEAVE_APPLICATION.UPDATED)
          getLeaveApplication(currentPage, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo);
       }
       resetForm({})
       handleCloseEdit();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const deleteLeaveApplication = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_LEAVE_APPLICATION +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.LEAVE_APPLICATION.DELETED)
        getLeaveApplication(currentPage, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo);
        // getLeaveApplication(currentPage1, searchedCompleted, 1, dateFilterCompleted.dateFrom, dateFilterCompleted.dateTo);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getLeaveApplication(null, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo)
      // getLeaveApplication(null, searchedCompleted, 1, dateFilterCompleted.dateFrom, dateFilterCompleted.dateTo)
      getLeaveType()
      setDateFormat(localStorage.getItem('date_format') ?? "")
      getAllEmployee()
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>My Leave Applications</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/leaves/holiday-management'>
            Leaves
          </Breadcrumb.Item>
          <Breadcrumb.Item active>My Leave Applications</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        {/*<Tabs*/}
        {/*    variant={'tabs'}*/}
        {/*    className='tabs'*/}
        {/*    id='controlled-tab-example'*/}
        {/*    activeKey={key}*/}
        {/*    onSelect={(k: any) => {*/}
        {/*      setKey(k)*/}
        {/*    }}*/}
        {/*    // sx={{*/}
        {/*    //   opacity: open ? 1 : 0,*/}
        {/*    //   fontWeight: openDropdown === 0 ? 700 : 100,*/}
        {/*    // }}*/}
        {/*>*/}
        {/*  <Tab  eventKey='pending' title='Pending'>*/}
            <Card className='border-0 p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <Formik
                        initialValues={
                          {
                            status: '',
                            date_from: '',
                            date_to: ''
                          }
                        }
                        onSubmit={filterLeaveApplicationPending}
                    >
                      {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                              <Col sm={2}>
                                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                  <Form.Label className='fw-bold form-labels'>Status</Form.Label>
                                  <Form.Select
                                      size={'sm'}
                                      name="status"
                                      value={values.status}
                                      onChange={(e: any) => {
                                        handleChange(e);
                                        handleSubmit();
                                        setStatus(e.target.value)
                                      }}
                                      onBlur={handleBlur}
                                      isInvalid={touched.status && !!errors.status}
                                  >
                                    <option value="0">All Status</option>
                                    <option value="1">Pending</option>
                                    <option value="3">Approved</option>
                                    <option value="2">Disapproved</option>
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.status}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                  <Form.Label className='fw-bold form-labels'>Date From</Form.Label>
                                  <InputGroup className='mb-2 ' size={'sm'}>
                                    <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                      <Calendar/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        size={'sm'}
                                        type='date'
                                        placeholder='Friday, June 03, 2022'
                                        name='date_from'
                                        value={values.date_from}
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          values.date_from = e.target.value
                                          handleSubmit();
                                        }}
                                        onBlur={handleBlur}
                                        isInvalid={touched.date_from && !!errors.date_from}
                                        // max={moment().format("YYYY-MM-DD")}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.date_from}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              <Col sm={2}>
                                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                  <Form.Label className='fw-bold form-labels'>Date To</Form.Label>
                                  <InputGroup className='mb-2 ' size={'sm'}>
                                    <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                      <Calendar/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        size={'sm'}
                                        type='date'
                                        placeholder='Friday, June 03, 2022'
                                        name='date_to'
                                        value={values.date_to}
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          values.date_to = e.target.value
                                          handleSubmit();
                                        }}
                                        onBlur={handleBlur}
                                        isInvalid={touched.date_to && !!errors.date_to}
                                        // max={moment().format("YYYY-MM-DD")}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.date_to}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              <Col sm={2}></Col>

                              <Col sm={4} className='d-flex justify-content-end hidden'>
                                <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                                  <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                  <br />
                                  <InputGroup size='sm' className={'w-100'}>
                                    <Form.Control
                                        aria-label='search'
                                        aria-describedby='inputGroup-sizing-sm'
                                        placeholder='Search...'
                                        onChange={handleChangeSearchedKeyword}
                                    />
                                    <InputGroup.Text className='bg-transparent search-input '>
                                      <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                  </InputGroup>
                                </Form.Group>
                                <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                                  <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                  <br />
                                  <Button
                                      onClick={handleShowCreate}
                                      className=' add-new-btn rounded mx-2 text-white'
                                      variant='warning'
                                  >
                                    <EditIcon/>
                                    &nbsp;Apply for Leave
                                  </Button>{' '}
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
                <Table responsive size='sm' className='bordered-tabs-top mt-3'>
                  <thead>
                  <tr className='text-center'>
                    <th>No.</th>
                    <th>Leave Type</th>
                    <th>Date Applied</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Days</th>
                    <th style={{width: '15%'}}>Reason</th>
                    <th style={{width: '15%'}}>Remarks</th>
                    <th style={{ width: '50px' }}>Status</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <>
                    {lists.length > 0 ? (
                        lists.map((item, index) =>
                            <tr className='mb-2' key={index}>
                              <td>{from + index}</td>
                              <td>{item.leave_type?.leave_type_name}</td>
                              <td className='text-center'>{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                              <td className='text-center'>{moment(new Date(item.application_from_date ?? "")).format(dateFormat)}</td>
                              <td className='text-center'>{moment(new Date(item.application_to_date ?? "")).format(dateFormat)}</td>
                              <td className='text-center'>{handleDisplayDays(item.number_of_day)}</td>
                              <td className='text-center display-reason' title={item.purpose ?? ""}>{item.purpose}</td>
                              <td className='text-truncate table-responsive'>{item.remarks}</td>
                              <td className='text-center'>
                                <div className='d-grid'>
                                  <>
                                    {(() => {
                                      switch (item.status) {
                                        case '1':
                                          return (
                                              <Button
                                                  className=' p-1 pt-0 pb-0'
                                                  style={{ fontSize: '12px', color: 'white' }}
                                                  variant=' btn-secondary'
                                                  size='sm'
                                              >
                                                Pending
                                              </Button>
                                          )
                                        case '3':
                                          return (
                                              <Button
                                                  className=' p-1 pt-0 pb-0'
                                                  style={{ fontSize: '12px', color: 'white' }}
                                                  variant=' btn-success'
                                                  size='sm'
                                              >
                                                Approved
                                              </Button>
                                          )
                                        case '2':
                                          return (
                                              <Button
                                                  className=' p-1 pt-0 pb-0'
                                                  style={{ fontSize: '12px', color: 'white' }}
                                                  variant=' btn-danger'
                                                  size='sm'
                                              >
                                                Disapproved
                                              </Button>
                                          )
                                      }
                                    })()}
                                  </>
                                </div>
                              </td>
                              <td className='text-center'>
                                <Button
                                    onClick={() => showLeaveRequest(item.leave_application_id)}
                                    className=' p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant='btn-transparent'
                                    size='sm'
                                >
                                  <View/>
                                </Button>
                                {/*<Button*/}
                                {/*    onClick={() => showLeaveApplication(item.leave_application_id)}*/}
                                {/*    className=' p-1 pt-0 pb-0'*/}
                                {/*    style={{ fontSize: '12px', color: 'white' }}*/}
                                {/*    variant=' btn-transparent'*/}
                                {/*    size='sm'*/}
                                {/*>*/}
                                {/*  <Write/>*/}
                                {/*</Button>*/}
                                {
                                    JSON.parse(localStorage.getItem('role') ?? "") == "Supervisor" ?
                                        <Button
                                            onClick={() => handleShowConfirm(item.leave_application_id)}
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-transparent'
                                            size='sm'
                                        >
                                          <Delete/>
                                        </Button>
                                        : ""
                                }

                              </td>
                            </tr>
                        )
                    ) : (
                        <tr className='no-item'>
                          <td colSpan={10}>{TEXT.NOT_FOUND}</td>
                        </tr>
                    )}
                  </>
                  </tbody>
                </Table>

                <Pagination
                    itemsCount={itemsCount}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getLeaveApplication(page, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo);
                    }
                    }
                />


              </Card.Body>
            </Card>
        {/*  </Tab>*/}

        {/*  <Tab  eventKey='completed' title='Completed'>*/}
        {/*    <Card className='border-0 p-0 bordered-card-tabs'>*/}
        {/*      <Card.Body>*/}
        {/*        <Row>*/}
        {/*          <Col sm={12}>*/}
        {/*            <Formik*/}
        {/*                initialValues={*/}
        {/*                  {*/}
        {/*                    date_from: '',*/}
        {/*                    date_to: ''*/}
        {/*                  }*/}
        {/*                }*/}
        {/*                onSubmit={filterLeaveApplicationCompleted}*/}
        {/*            >*/}
        {/*              {({*/}
        {/*                  values,*/}
        {/*                  errors,*/}
        {/*                  touched,*/}
        {/*                  handleChange,*/}
        {/*                  handleBlur,*/}
        {/*                  handleSubmit,*/}
        {/*                  isSubmitting*/}
        {/*                }) => (*/}
        {/*                  <Form noValidate onSubmit={handleSubmit}>*/}
        {/*                    <Row>*/}
        {/*                      <Col sm={2}>*/}
        {/*                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>*/}
        {/*                          <Form.Label className='fw-bold form-labels'>Date From</Form.Label>*/}
        {/*                          <InputGroup className='mb-2 ' size={'sm'}>*/}
        {/*                            <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>*/}
        {/*                              <Calendar/>*/}
        {/*                            </InputGroup.Text>*/}
        {/*                            <Form.Control*/}
        {/*                                size={'sm'}*/}
        {/*                                type='date'*/}
        {/*                                placeholder='Friday, June 03, 2022'*/}
        {/*                                name='date_from'*/}
        {/*                                value={values.date_from}*/}
        {/*                                onChange={(e: any) => {*/}
        {/*                                  handleChange(e);*/}
        {/*                                  values.date_from = e.target.value*/}
        {/*                                  handleSubmit();*/}
        {/*                                }}*/}
        {/*                                onBlur={handleBlur}*/}
        {/*                                isInvalid={touched.date_from && !!errors.date_from}*/}
        {/*                                max={moment().format("YYYY-MM-DD")}*/}
        {/*                            />*/}
        {/*                            <Form.Control.Feedback type="invalid">*/}
        {/*                              {errors.date_from}*/}
        {/*                            </Form.Control.Feedback>*/}
        {/*                          </InputGroup>*/}
        {/*                        </Form.Group>*/}
        {/*                      </Col>*/}
        {/*                      <Col sm={2}>*/}
        {/*                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>*/}
        {/*                          <Form.Label className='fw-bold form-labels'>Date To</Form.Label>*/}
        {/*                          <InputGroup className='mb-2 ' size={'sm'}>*/}
        {/*                            <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>*/}
        {/*                              <Calendar/>*/}
        {/*                            </InputGroup.Text>*/}
        {/*                            <Form.Control*/}
        {/*                                size={'sm'}*/}
        {/*                                type='date'*/}
        {/*                                placeholder='Friday, June 03, 2022'*/}
        {/*                                name='date_to'*/}
        {/*                                value={values.date_to}*/}
        {/*                                onChange={(e: any) => {*/}
        {/*                                  handleChange(e);*/}
        {/*                                  values.date_to = e.target.value*/}
        {/*                                  handleSubmit();*/}
        {/*                                }}*/}
        {/*                                onBlur={handleBlur}*/}
        {/*                                isInvalid={touched.date_to && !!errors.date_to}*/}
        {/*                                max={moment().format("YYYY-MM-DD")}*/}
        {/*                            />*/}
        {/*                            <Form.Control.Feedback type="invalid">*/}
        {/*                              {errors.date_to}*/}
        {/*                            </Form.Control.Feedback>*/}
        {/*                          </InputGroup>*/}
        {/*                        </Form.Group>*/}
        {/*                      </Col>*/}
        {/*                      <Col sm={4}></Col>*/}

        {/*                      <Col sm={4} className='d-flex justify-content-end hidden'>*/}
        {/*                        <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>*/}
        {/*                          <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>*/}
        {/*                          <br />*/}
        {/*                          <InputGroup size='sm' className={'w-100'}>*/}
        {/*                            <Form.Control*/}
        {/*                                aria-label='search'*/}
        {/*                                aria-describedby='inputGroup-sizing-sm'*/}
        {/*                                placeholder='Search...'*/}
        {/*                                onChange={handleChangeSearchedKeywordForCompleted}*/}
        {/*                            />*/}
        {/*                            <InputGroup.Text className='bg-transparent search-input '>*/}
        {/*                              <FontAwesomeIcon icon={faSearch} />*/}
        {/*                            </InputGroup.Text>*/}
        {/*                          </InputGroup>*/}
        {/*                        </Form.Group>*/}
        {/*                      </Col>*/}
        {/*                    </Row>*/}
        {/*                  </Form>*/}
        {/*              )}*/}
        {/*            </Formik>*/}
        {/*          </Col>*/}

        {/*        </Row>*/}
        {/*        <Table responsive size='sm' className='bordered-tabs-top mt-3'>*/}
        {/*          <thead>*/}
        {/*          <tr className='text-center'>*/}
        {/*            <th>No.</th>*/}
        {/*            <th>Leave Type</th>*/}
        {/*            <th>Date Applied</th>*/}
        {/*            <th>From</th>*/}
        {/*            <th>To</th>*/}
        {/*            <th>Days</th>*/}
        {/*            <th style={{width: '15%'}}>Reason</th>*/}
        {/*            <th style={{width: '15%'}}>Remarks</th>*/}
        {/*            <th style={{ width: '50px' }}>Status</th>*/}
        {/*            <th>Actions</th>*/}
        {/*          </tr>*/}
        {/*          </thead>*/}
        {/*          <tbody>*/}
        {/*          <>*/}
        {/*            {listsCompleted.length > 0 ? (*/}
        {/*                listsCompleted.map((item, index) =>*/}
        {/*                    <tr className='mb-2' key={index}>*/}
        {/*                      <td>{from1 + index}</td>*/}
        {/*                      <td>{item.leave_type?.leave_type_name}</td>*/}
        {/*                      <td className='text-center'>{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>*/}
        {/*                      <td className='text-center'>{moment(new Date(item.application_from_date ?? "")).format(dateFormat)}</td>*/}
        {/*                      <td className='text-center'>{moment(new Date(item.application_to_date ?? "")).format(dateFormat)}</td>*/}
        {/*                      <td className='text-center'>{handleDisplayDays(item.number_of_day)}</td>*/}
        {/*                      <td className='text-center display-reason' title={item.purpose ?? ""}>{item.purpose}</td>*/}
        {/*                      <td className='text-truncate table-responsive'>{item.remarks}</td>*/}
        {/*                      <td className='text-center'>*/}
        {/*                        <div className='d-grid'>*/}
        {/*                          <>*/}
        {/*                            {(() => {*/}
        {/*                              switch (item.status) {*/}
        {/*                                case '1':*/}
        {/*                                  return (*/}
        {/*                                      <Button*/}
        {/*                                          className=' p-1 pt-0 pb-0'*/}
        {/*                                          style={{ fontSize: '12px', color: 'white' }}*/}
        {/*                                          variant=' btn-secondary'*/}
        {/*                                          size='sm'*/}
        {/*                                      >*/}
        {/*                                        Pending*/}
        {/*                                      </Button>*/}
        {/*                                  )*/}
        {/*                                case '3':*/}
        {/*                                  return (*/}
        {/*                                      <Button*/}
        {/*                                          className=' p-1 pt-0 pb-0'*/}
        {/*                                          style={{ fontSize: '12px', color: 'white' }}*/}
        {/*                                          variant=' btn-success'*/}
        {/*                                          size='sm'*/}
        {/*                                      >*/}
        {/*                                        Approved*/}
        {/*                                      </Button>*/}
        {/*                                  )*/}
        {/*                                case '2':*/}
        {/*                                  return (*/}
        {/*                                      <Button*/}
        {/*                                          className=' p-1 pt-0 pb-0'*/}
        {/*                                          style={{ fontSize: '12px', color: 'white' }}*/}
        {/*                                          variant=' btn-danger'*/}
        {/*                                          size='sm'*/}
        {/*                                      >*/}
        {/*                                        Disapproved*/}
        {/*                                      </Button>*/}
        {/*                                  )*/}
        {/*                              }*/}
        {/*                            })()}*/}
        {/*                          </>*/}
        {/*                        </div>*/}
        {/*                      </td>*/}
        {/*                      <td className='text-center'>*/}
        {/*                        <Button*/}
        {/*                            onClick={() => showLeaveRequest(item.leave_application_id)}*/}
        {/*                            className=' p-1 pt-0 pb-0'*/}
        {/*                            style={{ fontSize: '12px', color: 'white' }}*/}
        {/*                            variant='btn-transparent'*/}
        {/*                            size='sm'*/}
        {/*                        >*/}
        {/*                          <View/>*/}
        {/*                        </Button>*/}
        {/*                        /!*<Button*!/*/}
        {/*                        /!*    onClick={() => showLeaveApplication(item.leave_application_id)}*!/*/}
        {/*                        /!*    className=' p-1 pt-0 pb-0'*!/*/}
        {/*                        /!*    style={{ fontSize: '12px', color: 'white' }}*!/*/}
        {/*                        /!*    variant=' btn-transparent'*!/*/}
        {/*                        /!*    size='sm'*!/*/}
        {/*                        /!*>*!/*/}
        {/*                        /!*  <Write/>*!/*/}
        {/*                        /!*</Button>*!/*/}
        {/*                        {*/}
        {/*                          JSON.parse(localStorage.getItem('role') ?? "") == "Supervisor" ?*/}
        {/*                              <Button*/}
        {/*                                  onClick={() => handleShowConfirm(item.leave_application_id)}*/}
        {/*                                  className=' p-1 pt-0 pb-0'*/}
        {/*                                  style={{ fontSize: '12px', color: 'white' }}*/}
        {/*                                  variant=' btn-transparent'*/}
        {/*                                  size='sm'*/}
        {/*                              >*/}
        {/*                                <Delete/>*/}
        {/*                              </Button>*/}
        {/*                            : ""*/}
        {/*                        }*/}

        {/*                      </td>*/}
        {/*                    </tr>*/}
        {/*                )*/}
        {/*            ) : (*/}
        {/*                <tr className='no-item'>*/}
        {/*                  <td colSpan={10}>{TEXT.NOT_FOUND}</td>*/}
        {/*                </tr>*/}
        {/*            )}*/}
        {/*          </>*/}
        {/*          </tbody>*/}
        {/*        </Table>*/}

        {/*        <Pagination*/}
        {/*            itemsCount={itemsCount1}*/}
        {/*            itemsPerPage={itemsPerPage1}*/}
        {/*            currentPage={currentPage1}*/}
        {/*            lastPage={lastPage1}*/}
        {/*            setCurrentPage={setCurrentPage1}*/}
        {/*            alwaysShown={false}*/}
        {/*            pageClicked={(page: number) => {*/}
        {/*              getLeaveApplication(page, searchedCompleted, 1, dateFilterCompleted.dateFrom, dateFilterCompleted.dateTo);*/}
        {/*            }*/}
        {/*            }*/}
        {/*        />*/}


        {/*      </Card.Body>*/}
        {/*    </Card>*/}
        {/*  </Tab>*/}
        {/*</Tabs>*/}

        <Dialog
            show={showConfirm}
            setShow={handleCloseConfirm}
            setEvent={() => {
              deleteLeaveApplication(item.leave_application_id)
            }
            }
        />

        <Loader show={showLoading}/>

        {/* ADD MODAL*/}
        <Formik
          initialValues={{
            employee_id: 0,
            leave_type_id: '',
            application_from_date: '',
            application_to_date: '',
            number_of_day: '',
            purpose: '',
            isHalfOrWholeDay: ''
          }}
          validationSchema={validationSchema}
          onSubmit={postLeaveApplication}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form id="leaveApplicationCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Leave Application Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold form-labels required'>Employees</Form.Label>
                            <Select
                                name="employee_id"
                                value={selectedEmployeeOption}
                                options={employeeOptions}
                                onChange={(e:any )=> {
                                  handleChangeDropdownEmployee(e)
                                  values.employee_id = e.value
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.employee_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Leave Type</Form.Label>
                            <Form.Select 
                              size={'sm'}
                              name="leave_type_id"
                              value={values.leave_type_id}
                              onChange={e => {
                                handleChange(e)
                                showLeaveBalance(parseInt(e.target.value))
                              }}
                              onBlur={handleBlur}
                              isInvalid={touched.leave_type_id && !!errors.leave_type_id}
                              >
                              <option value="">Select Leave Type</option>
                              {leaveTypes.map((leaveType, index) => (
                                <option value={leaveType.leave_type_id} key={index}>{leaveType.leave_type_name}</option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.leave_type_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>No. of Days </Form.Label>
                            <Form.Control 
                              size='sm' 
                              type='number' 
                              step={0.5} 
                              placeholder=''
                              name="number_of_day"
                              value={values.number_of_day}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.number_of_day && !!errors.number_of_day} 
                              disabled={true}
                              />
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.number_of_day}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Balance </Form.Label>
                            <Form.Control 
                              size='sm' 
                              type='number' 
                              step={0.5} 
                              placeholder='' 
                              value={leaveCredit.credits}
                              disabled={true}
                              />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Date From </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="application_from_date"
                                value={values.application_from_date}
                                onChange={e => {
                                  handleChange(e)
                                  if (e.target.value != '' && values.application_to_date != '') {
                                    const days = getNumberOfDays(
                                      e.target.value,
                                      values.application_to_date
                                    );
                                    values.number_of_day = days.toString();
                                  }
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.application_from_date && !!errors.application_from_date}
                                />
                                <Form.Control.Feedback className="left" type="invalid">
                                  {errors.application_from_date}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Date To </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="application_to_date"
                                value={values.application_to_date}
                                onChange={e => {
                                  handleChange(e)
                                  if (e.target.value != '' && values.application_from_date != '') {
                                    const days = getNumberOfDays(
                                      values.application_from_date,
                                      e.target.value
                                    );
                                    values.number_of_day = days.toString();
                                  }
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.application_to_date && !!errors.application_to_date}  
                                />
                              <Form.Control.Feedback className="left" type="invalid">
                                {errors.application_to_date}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                      <Form.Label className='fw-bold'>Reason</Form.Label>
                      <Form.Control 
                        as='textarea' 
                        rows={3}
                        name="purpose"
                        value={values.purpose}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.purpose && !!errors.purpose} 
                        />
                        <Form.Control.Feedback className="left" type="invalid">
                          {errors.application_to_date}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {['radio'].map((type: any) => (
                      <div key={`inline-${type}`} className='mb-3'>
                        <Form.Check
                          inline
                          label='Whole Day'
                          name='isHalfOrWholeDay'
                          type={type}
                          value='whole_day'
                          id={`inline-${type}-1`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            if (values.application_from_date  != '' && values.application_to_date != '') {
                              const days = getNumberOfDays(
                                values.application_from_date,
                                values.application_to_date
                              );
                              values.number_of_day = days.toString();
                            }
                          }}
                          checked={values.isHalfOrWholeDay === 'whole_day'}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />
                        <Form.Check
                          inline
                          label='First Half'
                          name='isHalfOrWholeDay'
                          type={type}
                          value='first_half'
                          id={`inline-${type}-2`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            values.number_of_day = '0.5';
                          }}
                          checked={values.isHalfOrWholeDay === 'first_half'}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />
                        <Form.Check
                          inline
                          name='isHalfOrWholeDay'
                          label='Second Half'
                          type={type}
                          value='second_half'
                          id={`inline-${type}-3`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            values.number_of_day = '0.5';
                          }}
                          checked={values.isHalfOrWholeDay === 'second_half'}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />

                        <span style={{ color: '#dc3545', fontSize: '0.875em' }}>
                          {touched.isHalfOrWholeDay && errors.isHalfOrWholeDay}
                        </span>
                      </div>
                    ))}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button
                    type="submit" 
                    disabled={isSubmitting} 
                    form="leaveApplicationCreate" 
                    variant='primary text-white'
                    size={'sm'}
                    className='mx-2'
                    >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
          initialValues={item}
          validationSchema={validationSchema}
          onSubmit={updateLeaveApplication}
          enableReinitialize
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form id="leaveApplicationEdit" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Edit Leave Application</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold form-labels required'>Employees</Form.Label>
                            <Select
                                name="employee_id"
                                value={selectedEmployeeOptionEdit}
                                options={employeeOptions}
                                onChange={(e:any )=> {
                                  handleChangeDropdownEmployeeEdit(e)
                                  values.employee_id = e.value
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.employee_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Leave Type</Form.Label>
                            <Form.Select 
                              size={'sm'}
                              name="leave_type_id"
                              value={values.leave_type_id}
                              onChange={e => {
                                handleChange(e)
                                showLeaveBalanceOnEdit(parseInt(e.target.value))
                              }}
                              onBlur={handleBlur}
                              isInvalid={touched.leave_type_id && !!errors.leave_type_id}
                              >
                              <option value="">Select Leave Type</option>
                              {leaveTypes.map((leaveType, index) => (
                                <option value={leaveType.leave_type_id} key={index}>{leaveType.leave_type_name}</option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.leave_type_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>No. of Days </Form.Label>
                            <Form.Control 
                              size='sm' 
                              type='number' 
                              step={0.5} 
                              placeholder=''
                              name="number_of_day"
                              value={values.number_of_day}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.number_of_day && !!errors.number_of_day} 
                              disabled={true}
                              />
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.number_of_day}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Balance </Form.Label>
                            <Form.Control 
                              size='sm' 
                              type='number' 
                              step={0.5} 
                              placeholder='' 
                              value={leaveCreditOnEdit.credits}
                              disabled={true}
                              />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Date From </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="application_from_date"
                                value={values.application_from_date}
                                onChange={e => {
                                  handleChange(e)
                                  if (e.target.value != '' && values.application_to_date != '') {
                                    const days = getNumberOfDays(
                                      e.target.value,
                                      values.application_to_date
                                    );
                                    values.number_of_day = days.toString();
                                  }
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.application_from_date && !!errors.application_from_date} 
                                />
                                <Form.Control.Feedback className="left" type="invalid">
                                  {errors.application_from_date}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Date To </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="application_to_date"
                                value={values.application_to_date}
                                onChange={e => {
                                  handleChange(e)
                                  if (e.target.value != '' && values.application_from_date != '') {
                                    const days = getNumberOfDays(
                                      values.application_from_date,
                                      e.target.value
                                    );
                                    values.number_of_day = days.toString();
                                  }
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.application_to_date && !!errors.application_to_date}  
                                />
                              <Form.Control.Feedback className="left" type="invalid">
                                {errors.application_to_date}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                      <Form.Label className='fw-bold'>Reason</Form.Label>
                      <Form.Control 
                        as='textarea' 
                        rows={3}
                        name="purpose"
                        value={values.purpose}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.purpose && !!errors.purpose} 
                        />
                        <Form.Control.Feedback className="left" type="invalid">
                          {errors.application_to_date}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {['radio'].map((type: any) => (
                      <div key={`inline-${type}`} className='mb-3'>
                        <Form.Check
                          inline
                          label='Whole Day'
                          name='isHalfOrWholeDay'
                          type={type}
                          value='whole_day'
                          id={`inline-${type}-1`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            if (values.application_from_date  != '' && values.application_to_date != '') {
                              const days = getNumberOfDays(
                                values.application_from_date,
                                values.application_to_date
                              );
                              values.number_of_day = days.toString();
                            }
                          }}
                          checked={values.isHalfOrWholeDay == 'whole_day' ? true : false}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />
                        <Form.Check
                          inline
                          label='First Half'
                          name='isHalfOrWholeDay'
                          type={type}
                          value='first_half'
                          id={`inline-${type}-2`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            values.number_of_day = '0.5';
                          }}
                          checked={values.isHalfOrWholeDay == 'first_half' ? true : false}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />
                        <Form.Check
                          inline
                          name='isHalfOrWholeDay'
                          label='Second Half'
                          type={type}
                          value='second_half'
                          id={`inline-${type}-3`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            values.number_of_day = '0.5';
                          }}
                          checked={values.isHalfOrWholeDay == 'second_half' ? true : false}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />

                        <span style={{ color: '#dc3545', fontSize: '0.875em' }}>
                          {touched.isHalfOrWholeDay && errors.isHalfOrWholeDay}
                        </span>
                      </div>
                    ))}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                    Cancel
                  </Button>
                  <Button
                    type="submit" 
                    disabled={isSubmitting} 
                    form="leaveApplicationEdit" 
                    variant='primary text-white'
                    size={'sm'}
                    className='mx-2'
                    >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>
        <br />

        {/* DETAIL MODAL*/}
        <Modal className='modal' centered size={'lg'} show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>
              <Stack direction='horizontal'>
                <div>
                  {
                    item.employee.photo != "" ?
                        <Avatar
                            sx={{ width: 65, height: 65 }}
                            src={process.env.REACT_APP_IMAGE_URL+ item.employee.photo}
                        ></Avatar> :
                        <Avatar
                            sx={{ width: 65, height: 65}}
                            src={""}
                        ></Avatar>
                  }
                </div>
                <div className='mx-3'>
                  &nbsp;{item.employee?.first_name} {item.employee?.last_name}
                  <br />
                  &nbsp;<span className='text-muted fw-light'>{item.employee?.user_name?.role?.role_name}</span>
                </div>
              </Stack>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      Leave Type:
                    </td>
                    <td>{item.leave_type?.leave_type_name}</td>
                  </tr>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      No. of Days:
                    </td>
                    <td >{handleDisplayDays(item.number_of_day)}</td>
                  </tr>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      Applied By:
                    </td>
                    <td>{item.created_by_first_name} {item.created_by_last_name}</td>
                  </tr>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      Applied On:
                    </td>
                    <td>{item.application_date}</td>
                  </tr>
                  </tbody>
                </Table>
              </Col>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      Date of Leave:
                    </td>
                    <td>{item.application_from_date} - {item.application_to_date}</td>
                  </tr>

                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      Reason:
                    </td>
                    <td>{item.purpose}</td>
                  </tr>
                  {
                    item.status == "2" ?
                        <tr className='lh-1'>
                          <td className='fw-bold' width={120}>
                            Reason for Disapproval:
                          </td>
                          <td>{item.remarks}</td>
                        </tr> : ""
                  }
                  </tbody>
                </Table>
              </Col>

            </Row>
            <hr/>
            <Row style={{marginTop: '-15px'}}>
              <Table borderless className='mt-0 mb-0'>
                <tbody>
                <tr className='lh-1'>
                  <th className='text-center'>Approvals</th>
                </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col sm={12}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <th className='text-center' style={{width: '20%'}}>Levels</th>
                    <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                    <th className='text-center'>Role</th>
                    <th className='text-center' style={{width: '15%'}}>Status</th>
                    <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                  </tr>
                  {
                    item.request_status.length > 0 ? (
                        item.request_status.map((item1: any, index: any) =>
                            (
                                <tr key={index} className='lh-1'>
                                  <td>
                                    {
                                      index != 0 && item.request_status[index - 1].level == item1.level ?
                                          "" : <>
                                            <b>Level {item1.level}</b> : {item1.approvers_order == "0" ? "By Order" : "Anyone"}
                                          </>
                                    }
                                  </td>
                                  <td>
                                    <Stack direction='horizontal'>
                                      <div>
                                        {
                                          item1.employee?.photo != "" ?
                                              <Avatar
                                                  sx={{ width: 20, height: 20 }}
                                                  src={process.env.REACT_APP_IMAGE_URL+ item1.employee?.photo}
                                                  className={"profile-avatar"}
                                              ></Avatar> :
                                              <Avatar
                                                  sx={{ width: 20, height: 20}}
                                                  src={""}
                                                  className={"profile-avatar"}
                                              ></Avatar>
                                        }
                                      </div>
                                      <div style={{color: item1.current == "1" ? "#ff7043": ""}}>&nbsp;{item1.employee?.first_name} {item1.employee?.last_name}</div>
                                    </Stack>
                                  </td>
                                  <td>{item1.employee?.user_name?.role?.role_name}</td>
                                  {(() => {
                                    // Approved:2
                                    if (item1.status == '3') {

                                      return (
                                          <>
                                            <td className='fw-bold text-center'>
                                              <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                                Approved
                                              </Button>
                                            </td>
                                            <td className='text-center'>{moment(new Date(item1.approve_date ?? "")).format(dateFormat)}</td>
                                          </>
                                      )

                                      // Reject:3
                                    } else if(item1.status == '2') {
                                      return (
                                          <>
                                            <td className='fw-bold text-center'>
                                              <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                                Disapproved
                                              </Button>
                                            </td>
                                            <td className='text-center'>{moment(new Date(item1.reject_date ?? "")).format(dateFormat)}</td>

                                          </>
                                      )
                                    } else if(item1.status == '4') {
                                      return (
                                          <>
                                            <td className='text-center'>-</td>
                                            <td className='text-center'>-</td>
                                          </>

                                      )
                                    }

                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                              Pending
                                            </Button>
                                          </td>
                                          <td className='text-center'>-</td>
                                        </>
                                    )
                                  })()}


                                </tr>

                            )

                        )
                    ) : (
                        <></>
                    )
                  }
                  </tbody>
                </Table>
              </Col>

            </Row>
          </Modal.Body>
          <Modal.Footer>
            {/*<Button*/}
            {/*    onClick={() => rejectLeaveRequest(item.leave_application_id)}*/}
            {/*    variant='outline-primary'*/}
            {/*    className=' px-5 pe-5 mx-2'*/}
            {/*    size={'sm'}*/}
            {/*>*/}
            {/*  Rejected*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*    onClick={() => acceptLeaveRequest(item.leave_application_id)}*/}
            {/*    variant='primary'*/}
            {/*    className='text-white px-5 pe-5'*/}
            {/*    size={'sm'}*/}
            {/*>*/}
            {/*  Approved*/}
            {/*</Button>*/}
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  )
}
